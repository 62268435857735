@import "normalize.css";
@import "font-awesome.min.css";

@mixin respond-to($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('../fonts/OpenSans-Regular.ttf') format('truetype')
}

@font-face {
  font-family: 'Open Sans Bold';
  src: url('../fonts/OpenSans-Bold.ttf') format('truetype')
}

@font-face {
  font-family: 'Open Sans Semibold';
  src: url('../fonts/OpenSans-SemiBold.ttf') format('truetype')
}

@font-face {
  font-family: 'Open Sans Light';
  src: url('../fonts/OpenSans-Light.ttf') format('truetype')
}

$blue: #2a8eff;

.row {

  &:after {
    content: '';
    display: block;
    clear: both;
  }

}

body {
  font-family: 'Open Sans Regular', sans-serif;
  font-size: 14px;
  margin: 0;
  padding: 70px 0 0;
  box-sizing: border-box;

  @include respond-to(800px) {
    padding-top: 50px;
  }

}

.body-inner {
  padding-top: 63px;
  padding-left: 170px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.top-bar {
  width: 100%;
  height: 63px;
  background-color: #2D384E;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  padding-right: 30px;
  z-index: 150;

  &-user-bar {

    margin-left: auto;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;
    }

    &-username {
      margin-left: 18px;
      font-size: 16px;
      color: #FFFFFF;
    }

  }

  &-logo {

    width: 170px;
    padding-left: 20px;
    box-sizing: border-box;

    &-image {
      display: block;
      height: 28px;
    }

  }

}

.aside-menu {
  width: 170px;
  position: fixed;
  left: 0;
  top: 0;
  padding-top: 63px;
  box-sizing: border-box;
  overflow-y: auto;
  height: 100vh;
  z-index: 100;

  &-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    padding-top: 23px;
    padding-bottom: 20px;
    width: 100%;

    &-link {
      display: block;
      text-decoration: none;
      color: #5D6A86;
      font-size: 13px;
      margin-top: 21px;
      margin-left: 25px;
    }

    &-line {
      width: 100%;
      height: 1px;
      background-color: #5D6A86;
    }

  }

  &-ul {
    box-sizing: border-box;
    padding-top: 23px;

    &-element {
      margin-top: 8px;
      height: 38px;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      outline: none;
      text-decoration: none;

      &-icon {
        width: 17px;
        display: flex;

        &-no-active {
          display: block;
        }

        &-active {
          display: none;
        }

      }

      &-label {
        display: block;
        margin-left: 12px;
        font-size: 13px;
        color: #ffffff;
      }

      &:hover {
        opacity: .8;
      }

      &.active {
        background-color: #ffffff;

        & .aside-menu-ul-element-label {
          color: #2D384F;
        }

        & .aside-menu-ul-element-icon-active {
          display: block;
        }

        & .aside-menu-ul-element-icon-no-active {
          display: none;
        }

        &:hover {
          opacity: 1;
        }

      }

    }

  }

}

.create-page {

  &-title {
    color: #2D384F;
    font-size: 22px;
    line-height: 27px;
    font-family: 'Open Sans Bold', sans-serif;
    text-transform: uppercase;
  }

  &-inner {
    padding: 41px 41px 100px;
    box-sizing: border-box;
    position: relative;
  }

  &-avatar {
    width: 120px;
    height: 120px;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      margin-top: 28px;
    }

  }

  &-warning-block {
    margin-top: 23px;
    width: 100%;
    background: rgba(42, 142, 254, 0.13);
    display: flex;
    flex-direction: row;
    padding: 24px 32px;
    box-sizing: border-box;

    &-icon {
      width: 39px;
      height: 39px;
      display: block;
    }

    &-right {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &-title {
      display: block;
      font-size: 16px;
      line-height: 19px;
      color: #2D384F;
      font-family: 'Open Sans Semibold', sans-serif;
    }

    &-text {
      display: block;
      margin-top: 4px;
      font-size: 14px;
      line-height: 17px;
      color: #2D384F;

      &-link {
        margin-left: 4px;
        color: $blue;
        font-family: 'Open Sans Semibold', sans-serif;
        transition: all .2s ease-out;
        text-decoration: none;

        &:hover {
          opacity: .8;
        }

      }

    }

  }

  &-row {
    margin-top: 48px;
    background: #FFFFFF;
    width: 100%;
    display: flex;
    flex-direction: row;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);

    @include respond-to(800px) {
      flex-direction: column-reverse;
    }

  }

  &-block {
    width: 50%;
    box-sizing: border-box;

    @include respond-to(800px) {
      width: 100%;
    }

    &:nth-child(1) {
      border-right: 1px solid #E5E5E5;
    }

    &.large {
      width: calc(60% - 15px);
    }

    &.small {
      width: calc(40% - 15px);
    }

    &-element {
      margin-top: 23px;
    }

    &-row {

      &-col {
        width: calc(50% - 15px);
        float: left;

        @include respond-to(800px) {
          float: none;
          width: 100%;
        }

        &.large {
          width: calc(75% - 15px);

          @include respond-to(800px) {
            width: 100%;
          }

        }

        &.small {
          width: calc(25% - 15px);

          @include respond-to(800px) {
            width: 100%;
          }

        }

        &:nth-child(1) {
          margin-right: 15px;

          @include respond-to(800px) {
            margin-right: 0;
          }

        }

        &:nth-child(2) {
          margin-left: 15px;

          @include respond-to(800px) {
            margin-left: 0;
          }

        }

      }

    }

    &-inner {
      box-sizing: border-box;
      padding: 0 26px;

      @include respond-to(800px) {
        padding: 0 12px;
      }

    }

    &-title {
      display: block;
      color: #2D384F;
      font-size: 17px;
      line-height: 21px;
      height: 21px;
      font-family: 'Open Sans Bold', sans-serif;
      border-bottom: 1px solid #E5E5E5;
      padding: 15px 26px;

      &.second-title {
        margin-top: 40px;
        border-top: 1px solid #E5E5E5;
      }

    }

    &-checkbox {
      float: left;
      width: 30%;
      margin-left: 5%;
      margin-top: 35px;

      &:nth-child(3n + 1) {
        margin-left: 0;
      }

      &-no-margin {
        margin-top: 20px;
        margin-bottom: 10px;
      }

    }

    &-checkboxes {
      margin-bottom: 35px;
    }

    &-dop-row {
      padding-right: 150px;
      box-sizing: border-box;
      position: relative;
      width: 100%;

      &-right {
        position: absolute;
        right: 0;
        top: 0;
        height: 53px;
        padding-top: 51px;
        box-sizing: border-box;
      }

      &-field {
        float: left;
        width: calc(50% - 15px);

        &:first-child {
          margin-right: 15px;
        }

        &:nth-child(2) {
          margin-left: 15px;
        }

      }

    }

  }

}

.custom-text-field {

  &-label {
    font-size: 14px;
    line-height: 17px;
    display: block;
    color: #2D384F;
    font-family: 'Open Sans Light', sans-serif;

    &-icon {
      color: $blue;
      margin-left: 6px;
      font-size: 18px;
      cursor: pointer;
      margin-top: 2px;
    }

  }

  &-date {
    position: relative;
    width: 100%;
    cursor: pointer;

    &-icon {
      height: 18px;
      position: absolute;
      right: 7px;
      top: 7px;
      display: block;
    }

    & .react-datepicker-wrapper {
      width: 100%;
    }

  }

  &-icon {
    height: 18px;
    display: block;
    position: absolute;
    bottom: 7px;
    right: 5px;
  }

  &-input {

    &-tips {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    &-tip {
      background-color: #2a8eff;
      padding: 7px;
      margin-right: 7px;
      box-sizing: border-box;
      font-size: 12px;
      color: #FFFFFF;
      margin-top: 7px;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        opacity: .7;
      }

    }

  }


  & .custom-text-field-date input, &-input {
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid rgba(121, 132, 146, .5);
    border-radius: 2px;
    font-family: "Open Sans Regular", sans-serif;
    outline-color: $blue;
    font-size: 15px;
    color: #2D384F;

    &:disabled {
      background-color: rgba(121, 132, 146, .1);
    }

    &::placeholder {
      color: #798492;
    }

    &-inner {
      position: relative;
      width: 100%;
      margin-top: 4px;
    }

  }

  &-textarea {
    resize: none;
    width: 100%;
    padding: 8px 6px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid rgba(121, 132, 146, .5);
    border-radius: 2px;
    outline-color: $blue;
    color: #2D384F;
    font-size: 15px;
    font-family: "Open Sans Regular", sans-serif;

    &::placeholder {
      color: #798492;
    }

  }

  &.custom-text-error {

    & .custom-text-field-input, .custom-text-field-date input, & .custom-text-field-textarea {
      border-color: rgba(204, 0, 0, .5);
      color: #CC0000;
    }

    & .custom-text-field-label {
      color: #CC0000;
    }

    & .custom-select-top {
      border-color: rgba(204, 0, 0, .5);
    }

  }

  &-error-text {
    display: block;
    font-size: 14px;
    line-height: 17px;
    color: #CC0000;
    margin-top: 4px;
    font-family: 'Open Sans Light', sans-serif;
  }

}

.bottom-area {
  height: 60px;
  background: #FFFFFF;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  z-index: 50;
  box-sizing: border-box;

  &-inner {
    max-width: 1280px;
    margin: auto;
    display: flex;
    flex-direction: row;
    height: 60px;
    align-items: center;
    box-sizing: border-box;
  }

  &-right {
    margin-left: auto;
    flex-direction: row;
    display: flex;
  }

  &-submit-button {
    margin-left: 33px;
  }

}

@keyframes spinner {
  to {transform: rotate(360deg);}
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  margin-top: -7px;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: #ffffff;
  animation: spinner .6s linear infinite;
}

.blue-spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 14px;
  height: 14px;
  margin: auto;
  border-radius: 50%;
  border: 1px solid transparent;
  border-top-color: $blue;
  animation: spinner .6s linear infinite;
}

.custom-button {
  position: relative;
  height: 40px;
  display: flex;
  flex-direction: row;
  padding-left: 26px;
  padding-right: 26px;
  align-items: center;
  box-shadow: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 30px;
  transition: all .2s ease-out;
  outline: none;
  text-decoration: none;

  &-label {
    font-size: 16px;
    line-height: 20px;
  }

  &:hover {
    opacity: .7;
  }

  &-spinner {
    position: relative;
    margin-left: 12px;
    display: none;
  }

  &.loading {
    opacity: .8;

    & .custom-button-spinner {
      display: block;
    }

  }


  &:disabled {
    opacity: .8;
    cursor: inherit;

    &:hover {
      opacity: .8;
    }

  }

  &.blue {

    background-color: $blue;
    border-color: $blue;

    & .custom-button-label {
      color: #FFFFFF;
      font-family: 'Open Sans Semibold', sans-serif;
    }

    & .spinner:before {
      border-top-color: #ffffff;
    }

  }

  &.bordered {

    background-color: #ffffff;
    border-color: $blue;

    & .custom-button-label {
      color: $blue;
    }

    & .spinner:before {
      border-top-color: $blue;
    }

  }

  &.gray {

    background-color: #ffffff;
    border-color: #979797;

    & .custom-button-label {
      color: #979797;
    }

    & .spinner:before {
      border-top-color: #979797;
    }

  }

}

.user-inner {
  margin-top: 31px;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  padding: 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;

  &-image {
    width: 50px;
    height: 50px;
    display: block;
  }

  &-block-1 {
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
    border-right: 1px solid #E5E5E5;
  }

  &-block-2 {
    padding-left: 16px;
    box-sizing: border-box;
  }

  &-username {
    font-size: 14px;
    line-height: 20px;
    color: #2D384F;
    display: block;
    font-family: 'Open Sans Bold', sans-serif;
  }

  &-date {
    margin-top: 3px;
    display: block;
    font-size: 12px;
    line-height: 14px;
    color: #979797;
    font-family: 'Open Sans Semibold', sans-serif;
  }

  &-position {
    margin-top: 4px;
    display: block;
    font-size: 12px;
    line-height: 18px;
    color: #2D384F;
    font-family: 'Open Sans Semibold', sans-serif;
  }

  &-element {
    font-size: 12px;
    line-height: 14px;
    display: block;
    color: #2D384F;

    & b {
      font-family: 'Open Sans Bold', sans-serif;
    }

  }

}

.custom-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 20px;
  cursor: pointer;

  &-checkmark {
    background-color: #FFFFFF;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 2px;
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-inner {
      width: 13px;
      height: 13px;
      background-color: $blue;
      display: none;
    }

  }

  &-label {
    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
    color: #2D384F;
    user-select: none;
  }

  & input[type=checkbox] {
    width: 0;
    height: 0;
    opacity: 0;

    &:checked ~ .custom-checkbox-checkmark {

      & .custom-checkbox-checkmark-inner {
        display: block;
      }

    }

  }


}

.custom-switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  &-inner {
    background-color: #C6CBD4;
    border-radius: 10px;
    width: 30px;
    height: 16px;
    position: relative;

    &-circle {
      width: 14px;
      height: 14px;
      position: absolute;
      top: 1px;
      left: 1px;
      background-color: #fff;
      border-radius: 14px;
    }

  }

  &-label {
    margin-left: 11px;
    font-size: 14px;
    line-height: 17px;
    color: #798492;
    font-family: 'Open Sans Light', sans-serif;
  }

  &.selected {

    & .custom-switcher-inner {
      background-color: $blue;
    }

    & .custom-switcher-inner-circle {
      left: 15px;
    }

  }

}

.custom-select {

  position: relative;
  font-family: "Open Sans Regular", sans-serif;
  margin-top: 4px;

  &-tip {
    display: flex;
    flex-direction: row;
    height: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 6px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding-left: 6px;
    padding-right: 6px;
    background-color: $blue;

    &-label {
      font-size: 12px;
      color: #FFFFFF;
    }

    &-close {
      font-size: 12px;
      color: #FFFFFF;
      margin-left: 4px;
      display: block;
      cursor: pointer;
    }

  }

  &-top {
    width: 100%;
    height: 32px;
    line-height: 32px;
    padding-left: 7px;
    padding-right: 14px;
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid rgba(121, 132, 146, .5);
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;

    &-input {
      font-size: 15px;
      height: 30px;
      width: 100%;
      padding-right: 40px;
      box-sizing: border-box;
      line-height: 30px;
      border: none;
      box-shadow: none;
      font-family: "Open Sans Regular", sans-serif;
      outline: none;
      color: #2D384F;

      &::placeholder {
        color: #798492;
        font-family: 'Open Sans Light', sans-serif;
      }

    }

    &.multi {
      height: auto;
      cursor: default;
      flex-wrap: wrap;

      & .custom-select-top-input {
        width: auto;
      }

    }

    &-spinner {
      position: absolute;
      right: 10px;
      top: 0;
      height: 32px;
      width: 32px;

      & .spinner:before {
        border-top-color: $blue;
      }

    }

    &-label {
      outline-color: $blue;
      color: #798492;
      font-family: 'Open Sans Light', sans-serif;
      font-size: 15px;
    }

    &-arrow {
      display: block;
      width: 9px;
      margin-left: auto;
    }

  }

  &-icon {
    width: 20px;
    height: 20px;
    margin-right: 13px;
  }

  &-submenu {
    position: absolute;
    z-index: 100;
    top: 32px;
    left: 0;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    max-height: 215px;
    overflow-y: auto;

    &-element {
      display: flex;
      flex-direction: row;
      align-items: center;
      box-sizing: border-box;
      padding: 6px 10px 6px 23px;
      font-size: 14px;
      line-height: 17px;
      color: #2D384F;
      cursor: pointer;

      &:hover {
        background-color: rgba(42, 142, 254, 0.2);
      }

    }

  }

}

.table-page {
  box-sizing: border-box;
  background-color: #FFFFFF;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  width: 100%;

  &-top {

    &-right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

  }

  &-aggregators {
    align-items: center;
    height: 100%;
  }

  &-num-value {
    margin-left: 10px;
  }

  &-block-view {
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;
    margin-bottom: 32px;
  }

  &-switcher {
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-element {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      width: 30px;
      height: 30px;
      margin-left: 5px;
      border-radius: 5px;
      background-color: #F5F7FB;
      cursor: pointer;

      &.active {
        background-color: $blue;
        color: #FFFFFF;
      }

      &:hover {
        opacity: .7;
      }

    }

  }

  &-bottom-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
    border-top: 1px solid #D7D7D7;
    padding-left: 32px;
    padding-right: 32px;
  }

  &-mini-button {
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 10px);
    box-shadow: none;
    border-radius: 6px;
    font-size: 12px;
    font-family: 'Open Sans Light', sans-serif;
    line-height: 16px;
    text-align: center;
    border: 1px solid $blue;
    background-color: transparent;
    color: $blue;
  }

  &-solid-button {
    margin-left: auto;
    position: relative;
    height: 24px;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    box-shadow: none;
    border-width: 1px;
    font-size: 12px;
    border-style: solid;
    border-radius: 24px;
    transition: all .2s ease-out;
    outline: none;
    text-decoration: none;
    background-color: $blue;
    border-color: $blue;
    color: #FFFFFF;
    cursor: pointer;

    & i {
      margin-right: 12px;
    }

    & .spinner:before {
      left: 70%;
    }

    &.loading {
      position: relative;
      padding-right: 34px;
    }

    &:hover, &:disabled {
      opacity: .6;
    }

  }

  &-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 14px;
    cursor: pointer;
    margin: auto;
    width: 14px;

    &-checkmark {
      background-color: #FFFFFF;
      border: 1px solid #DADADA;
      box-sizing: border-box;
      border-radius: 2px;
      width: 13px;
      height: 13px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-inner {
        width: 12px;
        height: 12px;
        background-color: $blue;
        display: none;
      }

    }

    & input[type=checkbox] {
      width: 0;
      height: 0;
      opacity: 0;

      &:checked ~ .table-page-checkbox-checkmark {

        & .table-page-checkbox-checkmark-inner {
          display: block;
        }

      }

    }


  }

  &-presets {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;

    &-element {
      margin-right: 17px;
      background-color: #EBEBEB;
      border-radius: 5px;
      font-size: 12px;
      padding: 6px 11px;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

    }

  }

  &-dop-row {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;

    &-right {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 32px;
      margin-left: auto;
    }

  }

  &-avatar {
    display: block;
    margin: auto;
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }

  &-messenger {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 2px;
    cursor: pointer;

    &:nth-child(1) {
      margin-left: 0;
    }

  }

  &-filters {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 27px;

    &-create-button {
      height: 32px;
      padding-left: 17px;
      padding-right: 11px;
      background-color: $blue;
      border-radius: 5px;
      box-shadow: none;
      border: none;
      font-size: 14px;
      line-height: 17px;
      color: #FFFFFF;
      transition: all .2s ease-in-out;

      &-icon {
        margin-left: 8px;
        font-size: 17px;
      }

      &:hover {
        opacity: .8;
      }

    }

    &-tip {
      height: 32px;
      background-color: #EBEBEB;
      border-radius: 5px;
      padding-left: 17px;
      padding-right: 11px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &-label {
        font-size: 14px;
        line-height: 17px;
        color: #8F949F;
        display: block;
      }

      &-close {
        font-size: 17px;
        line-height: 20px;
        color: #8F949F;
        margin-left: 8px;
        display: block;
      }

    }

  }

  &-text {
    display: block;
    font-size: 14px;
    line-height: 17px;
    height: 34px;
    overflow: hidden;
    color: #2D384F;
    padding-right: 30px;
    box-sizing: border-box;
    text-decoration: none;
    width: 100%;

    &.pointer {
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

    }

  }

  &-status {
    display: block;
    font-size: 14px;
    line-height: 17px;
    color: #2D384F;
    padding-right: 30px;
    box-sizing: border-box;

    &-circle {
      width: 7px;
      height: 7px;
      border-radius: 7px;
      display: inline-block;
      margin-right: 13px;

      &.green {
        background-color: #3FEB3F;
      }

      &.red {
        background-color: #CC0000;
      }

      &.orange {
        background-color: #ff5c23;
      }

    }

  }

  &-tip {
    background-color: rgba(63, 235, 63, 0.27);
    border-radius: 10px;
    display: block;
    text-align: center;
    font-size: 12px;
    font-family: 'Open Sans Semibold', sans-serif;
    line-height: 14px;
    padding-left: 12px;
    padding-right: 12px;
    color: #2D384F;
    height: 16px;
  }

  & table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 23px;

    & td {
      background-color: #F5F7FB;
      height: 41px;
      padding-top: 8px;
      vertical-align: top;
    }

    & th {
      padding-bottom: 22px;
      text-align: left;
      font-size: 12px;
      line-height: 15px;
      color: #878787;
      font-family: 'Open Sans Light', sans-serif;
      cursor: pointer;

      & .table-page-sort {
        display: inline-block;
        position: relative;
        top: 4px;
        margin-left: 3px;

        & i {
          display: block;
          font-size: 14px;
          color: #878787;
          line-height: 7px;

          &.active {
            color: #000000;
          }

        }

      }


    }

    & tr:nth-child(2n + 1) {

      & td {
        background-color: #FFFFFF;
      }

    }

    & tr.selected {

      & td {
        background-color: rgba(42, 142, 254, 0.22);
      }

    }

  }

  &-selected {

    &-text {
      font-size: 14px;
      line-height: 17px;
      color: #878787;

      & b {
        font-family: 'Open Sans Semibold', sans-serif;
        color: #2D384F;
      }

    }

    &-clear-link {
      margin-left: 9px;
      font-size: 14px;
      line-height: 17px;
      text-decoration-line: underline;
      color: #B6B6B6;
      cursor: pointer;
    }

    &-actions {
      margin-left: 45px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    &-action {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      &:first-child {
        margin-left: 0;
      }

      font-size: 14px;
      line-height: 17px;
      color: $blue;
      margin-left: 30px;
      cursor: pointer;

      &-icon {
        height: 14px;
        display: inline-block;
        margin-left: 12px;
      }

      &:hover {
        opacity: .7;
      }

    }

  }

  &-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid #D7D7D7;
    padding-left: 32px;
    padding-right: 32px;
    box-sizing: border-box;

    &-title {
      font-size: 22px;
      line-height: 27px;
      color: #2D384E;
      display: block;
      font-family: 'Open Sans Bold', sans-serif;
    }

    &-right {
      margin-left: auto;
    }

  }

  &-search-area {
    height: 32px;
    padding-left: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    border: 1px solid $blue;
    box-sizing: border-box;
    border-radius: 24px;
    width: 96px;
    transition: all .2s linear;

    &-icon {
      font-size: 13px;
      display: block;
      color: $blue;
    }

    &-input {
      height: 30px;
      line-height: 30px;
      outline: none;
      padding-left: 7px;
      box-sizing: border-box;
      border: 0;
      font-size: 14px;
      width: 56px;
      color: #000000;

      &::placeholder {
        color: $blue;
      }

    }

    &.open {

      width: 304px;

      & .table-page-search-area-input {
        width: 263px;
      }

    }

  }

  &-create-button {
    margin-left: 10px;
  }

  &-export-button {
    margin-left: 10px;
  }

  &-button {
    height: 32px;
    display: flex;
    border: 0;
    box-shadow: none;
    background-color: $blue;
    border-radius: 24px;
    align-items: center;
    padding-left: 16px;
    padding-right: 10px;
    flex-direction: row;
    transition: all .2s ease-out;
    text-decoration: none;

    &-label {
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
    }

    &-icon {
      height: 18px;
      display: block;
      margin-left: 10px;
    }

    &:hover {
      opacity: .8;
    }

  }

  &-content {
    padding-left: 32px;
    padding-bottom: 32px;
    padding-right: 32px;
    box-sizing: border-box;
  }

  &-loader {
    position: relative;
    min-height: calc(100vh - 300px);
  }

}

.switcher {
  display: flex;
  flex-direction: row;

  &-icon {
    width: 22px;
    height: 22px;
    cursor: pointer;
    margin-left: 12px;

    &:first-child {
      margin-left: 0;
    }

  }

}

.filter-modal {
  position: fixed;
  height: 350px;
  overflow-y: auto;
  width: 300px;
  padding: 50px 30px;
  box-sizing: border-box;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  z-index: 300;
  background-color: #fff;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.24);

  &-select {
    margin-top: 20px;
  }

  &-submit {
    position: absolute;
    bottom: 20px;
    left: 30px;
    width: calc(100% - 60px);
    height: 40px;
    display: block;
    padding-left: 26px;
    padding-right: 26px;
    align-items: center;
    box-shadow: none;
    border-width: 1px;
    border-style: solid;
    border-radius: 30px;
    transition: all 0.2s ease-out;
    outline: none;
    text-decoration: none;
    text-align: center;
    color: #FFFFFF;
    font-family: "Open Sans Semibold", sans-serif;
    font-size: 16px;
    line-height: 20px;
    background-color: $blue;
    border-color: $blue;
  }

  &-fade {
    width: 100%;
    height: 100%;
    z-index: 200;
    position: fixed;
    right: 0;
    top: 0;
  }

  &-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;

    &-title {
      font-size: 24px;
      line-height: 32px;
      color: #333333;
      font-family: 'Open Sans Bold', sans-serif;
    }

    &-clear {
      margin-left: 20px;
      font-size: 14px;
      line-height: 24px;
      text-decoration-line: underline;
      color: $blue;
      cursor: pointer;
    }

  }

  &-area {

    margin-top: 20px;

    &:first-child {
      margin-top: 7px;
    }

    &-title {
      font-size: 14px;
      line-height: 24px;
      color: #333333;
      display: block;
      font-family: 'Open Sans Semibold', sans-serif;
    }

  }

  &-checkboxes {
    margin-top: 16px;
  }

  &-checkbox {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }

  }

  &-switchers {
    margin-top: 16px;
  }

  &-switcher {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }

  }

}

.messengers {

  &-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
    margin-top: 14px;
    width: 100%;
  }

  &-create-button {
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 23px;
    border: 0;
    box-shadow: none;
    background-color: #ffffff;
    outline: none;
    transition: all .2s ease-in-out;


    &:hover {
      opacity: .8;
    }

    &-icon {
      width: 41px;
      height: 41px;
    }

    &-label {
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      font-family: 'Open Sans Light', sans-serif;
      color: $blue;
      margin-left: 16px;
    }

  }

  &-select {
    width: 150px;
  }

  &-field {
    margin-top: 4px;
    padding-left: 13px;
    padding-right: 13px;
    box-sizing: border-box;
    width: calc(100% - 182px);
  }

  &-delete-icon {
    margin-top: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    cursor: pointer;
    background-color: #ffffff;
    border: 0;
    box-shadow: none;
    outline: none;

    &-image {
      display: block;
      width: 14px;
      height: 16px;
    }

  }

}

.create-permissions {
  margin-top: 18px;

  &-area {

    margin-top: 22px;

    &:first-child {
      margin-top: 0;
    }

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 24px;

      &-arrow {
        width: 14px;
        display: block;
        cursor: pointer;
      }

      &-checkbox {
        margin-left: 10px;
      }

    }

    &-elements {
      margin-top: 12px;
      padding-left: 40px;
      box-sizing: border-box;
    }

    &-element {
      margin-top: 12px;

      &:first-child {
        margin-top: 0;
      }

    }

  }

}

.employee-page {
  padding: 41px;
  box-sizing: border-box;

  &-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 14px;

    &-link {
      height: 19px;
      display: flex;
      cursor: pointer;
      text-decoration: none;

      &-icon {
        width: 17px;
        display: block;
      }

      &-label {
        margin-left: 17px;
        font-size: 16px;
        line-height: 19px;
        font-family: 'Open Sans Semibold', sans-serif;
        color: #2D384F;
      }

    }

  }

  &-block-1 {
    width: 100%;
    margin-top: 21px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    padding: 21px 32px;
    box-sizing: border-box;
  }

  &-block-2 {
    width: calc(100% - 333px);
    float: left;
    padding: 21px 32px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }

  &-block-3 {
    width: 300px;
    margin-left: 33px;
    float: left;
    padding: 21px 32px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

    &-title {
      display: block;
      font-size: 17px;
      line-height: 21px;
      font-family: 'Open Sans Bold', sans-serif;
      color: #2D384F;
    }

  }

  &-row {
    margin-top: 35px;
  }


}

.empty-page {
  padding: 41px;
  box-sizing: border-box;

  &-inner {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: calc(100vh - 163px);
  }

  &-stringer {
    width: 170px;
    display: block;
  }

  &-text {
    display: block;
    text-align: center;
    width: 300px;
    font-size: 25px;
    line-height: 33px;
    font-family: 'Open Sans Semibold', sans-serif;
    color: #2D384E;
  }

  &-ufo {
    width: 300px;
    display: block;
  }

}


.task-page {

  box-sizing: border-box;
  margin-top: 20px;

  &-bottom {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 26px 21px;
    box-sizing: border-box;
  }

  &-loading {
    position: relative;
    width: 100%;
    height: calc(100vh - 163px);
  }

  &-inner {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }

  &-content {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &-input-links {

    width: 100%;
    margin-top: 12px;

    &-element {
      width: 100%;
      display: flex;
      flex-direction: row;
      height: 34px;
      align-items: center;

      &-input {
        width: calc(100% - 50px);
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
        padding-right: 6px;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid rgba(121, 132, 146, 0.5);
        border-radius: 2px;
        outline-color: $blue;
        color: #798492;
        font-size: 15px;
      }

      &-icon {
        margin-left: auto;
        height: 17px;
        display: block;
        cursor: pointer;
      }

    }

  }

  &-invoice-button {
    margin-top: 28px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    border: 1px solid $blue;
    box-sizing: border-box;
    border-radius: 30px;
    color: $blue;
    font-size: 14px;
    line-height: 17px;
    background-color: #FFFFFF;
    width: 100%;
  }

  &-field {
    width: 100%;
    margin-top: 12px;

    &.short {
      width: calc(50% - 10px);
    }

    &:nth-child(2n + 1) {
      margin-right: 10px;
    }

  }

  &-location-fields {
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 53px;

    & .custom-text-field {
      width: calc(100% - 170px);
    }

    & .custom-switcher {
      width: 150px;
      margin-left: 20px;
      margin-top: 18px;
    }

  }


  &-fields {
    width: 100%;
    margin-top: 6px;

    &-row {
      display: flex;
      width: 100%;
      flex-direction: row;

      & .task-page-field {
        width: calc(50% - 10px);

        &:nth-child(2n + 1) {
          margin-right: 20px;
        }

      }

    }

  }

  &-budget {

    margin-top: 23px;

    &:first-child {
      margin-top: 0;
    }

    &-value {
      display: block;
      text-align: center;
      font-size: 23px;
      line-height: 28px;
      color: $blue;
      font-family: 'Open Sans Bold', sans-serif;
    }

    &-label {
      display: block;
      text-align: center;
      font-size: 14px;
      line-height: 17px;
      color: #2D384F;
    }

  }

  &-tabs {
    flex-direction: row;
    display: flex;
    padding-left: 0;
    position: absolute;
    right: 30px;
    bottom: 0;
    margin-left: auto;
    box-sizing: border-box;

    &-element {
      height: 41px;
      display: block;
      padding-left: 21px;
      padding-right: 21px;
      box-sizing: border-box;
      line-height: 41px;
      background-color: #F6F7FB;
      border: 1px solid #E5E5E5;
      border-bottom-width: 0;
      border-radius: 15px 15px 0 0;
      margin-left: 16px;
      font-size: 14px;
      color: $blue;
      cursor: pointer;
      transition: all .2s ease-out;

      @include respond-to(700px) {
        margin-left: 0;
        border-radius: 0;
        font-size: 12px;
        border-left-width: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #E5E5E5;
        flex: 1;
        padding-left: 10px;
        padding-right: 10px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        opacity: .7;
      }

      &.disabled {
        opacity: .4;
        cursor: inherit;
      }

      &.active {
        font-family: 'Open Sans Bold', sans-serif;
        background: #FFFFFF;
        height: 41px;
        border-bottom-width: 0;

        &:hover {
          opacity: 1;
        }

      }

    }

  }

  &-members {
    width: 100%;

    &-create {
      display: flex;
      flex-direction: row;
      margin: 25px auto 0;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      width: 180px;

      &-icon {
        width: 26px;
        height: 26px;
        display: block;
        margin-right: 15px;
      }

      &-label {
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: underline;
        color: $blue;
      }

    }

  }

  &-member {
    margin-top: 20px;
    padding: 14px 19px;
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;

    &-left {
      width: 50px;

      &-image {
        width: 50px;
        height: 50px;
        border-radius: 50px;
      }

    }

    &-right {
      width: calc(100% - 50px);
      padding-left: 17px;
      box-sizing: border-box;
    }

    &-top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-label {
      display: block;
      margin-top: 4px;
      font-size: 12px;
      line-height: 14px;
      font-family: 'Open Sans Semibold', sans-serif;
      color: #798492;
    }

    &-name {
      display: block;
      font-size: 14px;
      line-height: 17px;
      color: #2D384F;
      text-decoration: none;
      font-family: 'Open Sans Semibold', sans-serif;
    }

    &-actions {
      margin-top: 4px;
    }

    &-action {
      display: block;
      font-size: 12px;
      line-height: 16px;
      color: #798492;
      margin-right: 10px;
      float: left;
    }

    &-icons {
      margin-left: auto;
      display: flex;
      flex-direction: row;
    }

    &-icon {
      width: 15px;
      height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 7px;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &-image {
        max-width: 15px;
        max-height: 15px;
        display: block;
      }

    }

  }

  &-points {
    padding-right: 10px;
    box-sizing: border-box;
    overflow: hidden;
    height: 41px;
    position: absolute;
    right: 30px;
    top: 7px;
    display: flex;
    align-items: center;
  }

  &-point {
    font-size: 14px;
    float: left;
    line-height: 17px;
    color: #ACB1BE;
    font-family: 'Open Sans Semibold', sans-serif;

    &:before {
      width: 5px;
      height: 5px;
      top: -1px;
      position: relative;
      border-radius: 5px;
      margin-left: 10px;
      margin-right: 10px;
      content: "";
      display: inline-block;
      background-color: #ACB1BE;
    }

    &:first-child {

      &:before {
        display: none;
      }

    }

    &.active {

      color: $blue;

      &:before {
        background-color: $blue;
      }

    }

  }

  &-aside {
    width: 250px;
    box-sizing: border-box;
    border-right: 1px solid #E5E5E5;
    padding: 20px;

    &-elements {
      margin-top: 21px;
    }

    &-element {
      margin-top: 13px;
      font-size: 14px;
      line-height: 17px;
      display: block;
      color: #2D384F;

      & b {
        font-family: 'Open Sans Bold', sans-serif;
      }

    }

    &-checkboxes {
      margin-top: 8px;
    }

    &-checkbox {
      margin-top: 23px;
    }

  }

  &-types {
    margin-top: 2px;

    &-checkbox {
      margin-top: 24px;
      float: left;
      width: 33.33%;
    }

  }

  &-notes {

    margin-top: 19px;

    &-create {

      display: flex;
      flex-direction: row;
      margin-top: 7px;

      &-input {
        background-color: #FFFFFF;
        border: 1px solid rgba(121, 132, 146, 0.5);
        box-sizing: border-box;
        border-radius: 6px;
        width: calc(100% - 55px);
        height: 31px;
        padding-left: 12px;
        padding-right: 12px;
        font-size: 12px;
        line-height: 15px;
        color: #798492;
      }

      &-button {
        width: 50px;
        margin-left: 5px;
        height: 31px;
        box-shadow: none;
        border: 1px solid $blue;
        background-color: $blue;
        border-radius: 6px;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        position: relative;
        font-family: 'Open Sans Semibold', sans-serif;
      }

    }

    &-area {
      margin-top: 4px;
      background-color: #F2F2F2;
      border-radius: 10px;
      padding: 16px 12px;
      box-sizing: border-box;
      position: relative;

      &-element {
        margin-top: 5px;
        display: block;

        &-time {
          font-size: 12px;
          line-height: 15px;
          color: #5C5C5C;
          font-family: 'Open Sans Semibold', sans-serif;
        }

        &-user {
          font-size: 12px;
          line-height: 15px;
          color: #5C5C5C;
          margin-left: 5px;

          & span {
            text-decoration: underline;
          }

        }

        &-text {
          font-size: 12px;
          line-height: 15px;
          color: #5C5C5C;
          margin-left: 5px;

          & span {
            font-family: 'Open Sans Semibold', sans-serif;
          }

        }

        &:first-child {
          margin-top: 0;
        }

      }

      &-date {
        background-color: #FFFFFF;
        border: 1px solid #DADADA;
        box-sizing: border-box;
        border-radius: 20px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 15px;
        color: #B6B6B6;
        position: absolute;
        height: 22px;
        top: -11px;
        left: calc(50% - 60px);
      }

    }

  }

  &-textarea {
    margin-top: 12px;
    width: 100%;
    height: 170px;
  }

  &-links {
    margin-top: 30px;

    &-label {
      display: block;
      font-size: 15px;
      line-height: 18px;
      color: #2D384F;
      margin-bottom: 5px;
    }

    &-element {

      display: flex;
      flex-direction: row;
      justify-content: center;
      height: 20px;
      overflow: hidden;
      margin-top: 5px;

      &-link {
        font-size: 15px;
        line-height: 18px;
        color: $blue;
        height: 20px;
        display: block;
        width: calc(100% - 25px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      &-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-left: auto;
      }

    }

  }

  &-right {
    width: calc(100% - 250px);
    position: relative;

    &-content {
      display: flex;
      flex-direction: row;

      &-col {
        width: 50%;
        border-right: 1px solid #E5E5E5;

        &:last-child {
          width: 50%;
          border-right-width: 0;
          border-bottom: 1px solid #E5E5E5;
        }

      }

    }

  }

  &-top {

    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    box-sizing: border-box;

    &-content {
      display: flex;
      flex-direction: row;
      align-content: center;
      padding: 20px 32px 12px;
      position: relative;
    }

    &-dots {
      position: absolute;
      right: 28px;
      top: 12px;

      &-icon {
        width: 6px;
        display: block;
        cursor: pointer;
      }

      &-submenu {
        position: absolute;
        right: 0;
        width: 170px;
        top: 30px;
        background: #FFFFFF;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
        border-radius: 4px;

        &-element {
          display: flex;
          align-items: center;
          height: 30px;
          padding-left: 23px;
          padding-right: 10px;
          font-size: 14px;
          line-height: 17px;
          color: #2D384F;
          cursor: pointer;

          &:hover {
            background: rgba(42, 142, 254, 0.2);
          }

        }

      }

    }

    &-map {
      width: 100px;
      height: 56px;
      border-radius: 4px;
      display: block;
    }

    &-info {
      margin-left: 21px;

      &-name {
        display: block;
        font-size: 25px;
        line-height: 30px;
        font-family: 'Open Sans Bold', sans-serif;
        color: #2D384E;
        max-width: 610px;
      }

      &-location {
        display: block;
        margin-top: 6px;
        font-size: 16px;
        height: 30px;
        line-height: 19px;
        color: #798492;
        font-family: 'Open Sans Light', sans-serif;

        & i {
          color: #A2A2A2;
          margin-right: 8px;
          display: inline-block;
        }

      }

    }

  }

  &-stringers {
    padding: 20px 26px 21px;
    box-sizing: border-box;
    width: 100%;

    &-area {

      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }

      &-title {
        display: block;
        font-size: 16px;
        line-height: 19px;
        color: #2D384E;
        font-family: 'Open Sans Bold', sans-serif;
      }

      &-content {
        margin-top: 22px;
      }

    }

  }

  &-block {
    padding: 20px 26px 21px;
    border-bottom: 1px solid #E5E5E5;
    box-sizing: border-box;

    &-top {
      flex-direction: row;
      display: flex;
      height: 26px;
      align-items: center;
    }

    &-title {
      color: #2D384F;
      font-family: 'Open Sans Bold', sans-serif;
      font-size: 16px;
      line-height: 19px;
    }

  }

}

.stringer-page {

  box-sizing: border-box;

  @include respond-to(700px) {
    margin-top: 0!important;
  }

  &-inner {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }

  &-content {
    display: flex;
    flex-direction: row;

    @include respond-to(700px) {
      flex-direction: column-reverse;
    }

  }

  &-videos {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    @include respond-to(700px) {
      padding-left: 12px;
      padding-right: 12px;
    }

    &-elements {
      margin-left: -28px;
      width: calc(100% + 28px);

      @include respond-to(700px) {
        margin-left: -12px;
        width: calc(100% + 12px);
      }

      & .video-card {
        width: 25%;

        @include respond-to(700px) {
          width: 50%;
        }

      }

    }

  }

  &-table {
    padding: 24px 26px 21px;
    box-sizing: border-box;
    width: 100%;

    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-title {
      display: block;
      color: #2D384F;
      font-family: "Open Sans Bold", sans-serif;
      font-size: 16px;
      line-height: 19px;
    }

    &-button {
      margin-left: auto;
      width: 121px;
      height: 31px;
      background-color: $blue;
      border-radius: 5px;
      border: none;
      box-shadow: none;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 17px;
      color: #FFFFFF;

      & span {
        font-size: 20px;
        margin-left: 6px;
        display: block;
        position: relative;
        top: 1px;
      }

    }

  }

  &-tabs {
    flex-direction: row;
    display: flex;
    left: -1px;
    position: relative;
    top: 1px;
    box-sizing: border-box;

    @include respond-to(700px) {
      left: 1px;
      top: 0;
      margin-top: 40px;
    }

    &-element {
      height: 41px;
      display: block;
      padding-left: 21px;
      padding-right: 21px;
      box-sizing: border-box;
      line-height: 41px;
      background-color: #F6F7FB;
      border: 1px solid #E5E5E5;
      border-bottom-width: 0;
      border-radius: 15px 15px 0 0;
      margin-left: 16px;
      font-size: 14px;
      color: $blue;
      cursor: pointer;
      transition: all .2s ease-out;

      @include respond-to(700px) {
        margin-left: 0;
        border-radius: 0;
        font-size: 12px;
      }

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        opacity: .7;
      }

      &.active {
        font-family: 'Open Sans Bold', sans-serif;
        background: #FFFFFF;
        height: 42px;
        border-bottom-width: 0;

        &:hover {
          opacity: 1;
        }

      }

      & .green {
        color: #43AA12;
      }

    }

  }

  &-portfolio {
    box-sizing: border-box;
    padding: 53px 32px;

    &-title {
      display: block;
      font-size: 21px;
      line-height: 26px;
      font-family: 'Open Sans Bold', sans-serif;
      color: #2D384F;
    }

  }

  &-note-area {
    margin-top: 38px;

    &-label {
      display: block;
      font-size: 14px;
      line-height: 17px;
      color: #2D384F;
      font-family: 'Open Sans Semibold', sans-serif;
    }

    &-textarea {
      margin-top: 15px;
      resize: none;
      width: 100%;
      height: 112px;
      padding: 10px 12px;
      box-sizing: border-box;
      font-size: 12px;
      line-height: 15px;
      color: #2D384F;
      outline-color: $blue;
    }

  }

  &-balance {

    display: block;
    padding-bottom: 5px;

    &-value {
      display: block;
      display: block;
      font-size: 23px;
      line-height: 28px;
      font-family: 'Open Sans Bold', sans-serif;
      text-align: center;
      color: $blue;

    }

    &-label {
      display: block;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #2D384F;
      font-family: 'Open Sans Light', sans-serif;
    }

  }

  &-info {

    &-element {

      margin-top: 17px;
      box-sizing: border-box;

      &-value {
        display: block;
        font-size: 23px;
        line-height: 28px;
        font-family: 'Open Sans Bold', sans-serif;
        text-align: center;
        color: #2D384F;

      }

      &-label {
        display: block;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        margin-top: 3px;
        color: #2D384F;
        font-family: 'Open Sans Light', sans-serif;
      }

    }

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;

      & .stringer-page-info-element {
        width: 50%;
      }

    }

  }

  &-aside {
    width: 250px;
    box-sizing: border-box;
    border-right: 1px solid #E5E5E5;

    @include respond-to(700px) {
      width: 100%;
    }

    &-sign-out {
      display: block;
      text-align: center;
      font-size: 14px;
      color: $blue;
      border-bottom: 1px solid #E5E5E5;
      cursor: pointer;
      padding: 10px;
      box-sizing: border-box;
    }

    &-delete {
      display: block;
      text-align: center;
      font-size: 14px;
      cursor: pointer;
      text-decoration: none;
      border-bottom: 1px solid #E5E5E5;
      color: #2D384F;
      font-family: 'Open Sans Bold', sans-serif;
      padding: 10px;
      box-sizing: border-box;
    }

    &-block-1 {
      border-bottom: 1px solid #E5E5E5;
      padding: 20px 26px 21px;
      box-sizing: border-box;
    }

    &-block-3 {
      box-sizing: border-box;
    }

    &-block-2 {
      padding: 20px 26px 21px;
      box-sizing: border-box;

      &-element {

        margin-top: 28px;

        &:first-child {
          margin-top: 0;
        }

        &-title {
          display: block;
          font-size: 16px;
          line-height: 19px;
          color: #2D384F;
          font-family: 'Open Sans Bold', sans-serif;
        }

        &-values {
          margin-top: 18px;
        }

        &-value {
          display: block;
          font-size: 14px;
          line-height: 17px;
          color: #2D384F;
          margin-top: 5px;

          &:first-child {
            margin-top: 0;
          }

        }

      }

    }

  }

  &-rating {

    padding: 20px 26px 21px;
    box-sizing: border-box;

    &-title {
      color: #2D384F;
      font-family: 'Open Sans Bold', sans-serif;
      font-size: 16px;
      line-height: 19px;
    }

    &-elements {
      margin-top: 15px;
    }

    &-element {
      margin-top: 14px;
      border-bottom: 1px solid #E5E5E5;
      width: 100%;
      padding-bottom: 19px;

      &:last-child {
        border-bottom-width: 0;
      }

      &-title {
        display: block;
        font-size: 16px;
        line-height: 19px;
        color: $blue;
        font-family: 'Open Sans Semibold', sans-serif;
      }

      &-top {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        height: 17px;
        align-items: center;
      }

      &-stars {

        display: flex;
        flex-direction: row;

        &-inner {
          display: flex;
          flex-direction: row;
        }

        &-label {
          margin-left: 6px;
          font-size: 14px;
          line-height: 17px;
          color: #2D384F;
          font-family: 'Open Sans Bold', sans-serif;
        }

      }

      &-star {
        width: 15px;
        height: 15px;
        display: block;
        margin-right: 2px;
      }

      &-date {
        margin-left: 17px;
        display: block;
        font-size: 12px;
        line-height: 15px;
        color: #2D384F;
      }

      &-text {
        display: block;
        margin-top: 14px;
        font-size: 13px;
        line-height: 16px;
        color: #2D384F;
      }

      &-bottom {
        display: flex;
        flex-direction: row;
      }

      &-client {
        display: block;
        font-size: 14px;
        line-height: 17px;
        margin-top: 13px;
        margin-left: auto;
        color: #000000;
      }

    }

  }

  &-block {
    padding: 20px 26px 21px;
    border-bottom: 1px solid #E5E5E5;
    box-sizing: border-box;

    &-top {
      flex-direction: row;
      display: flex;
      height: 26px;
      align-items: center;
    }

    &-title {
      color: #2D384F;
      font-family: 'Open Sans Bold', sans-serif;
      font-size: 16px;
      line-height: 19px;
    }

    &-right-text {
      margin-left: auto;
      color: #2D384F;
      font-family: 'Open Sans Bold', sans-serif;
      font-size: 17px;
      line-height: 21px;
    }

    &-description {
      margin-top: 23px;
      font-size: 12px;
      line-height: 15px;
    }

    &-element {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      &-label {
        width: 120px;
        display: block;
        font-size: 14px;
        line-height: 17px;
        color: #2D384F;
        font-family: 'Open Sans Semibold', sans-serif;
      }

      &-value {
        margin-left: auto;
        width: calc(100% - 120px);
      }

      &-messenger {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 9px;

        &:first-child {
          margin-top: 0;
        }

        &-image {
          display: block;
          width: 18px;
          height: 18px;
        }

        &-name {
          font-size: 14px;
          line-height: 17px;
          font-family: 'Open Sans Semibold', sans-serif;
          color: #2D384F;
          margin-left: 9px;
          display: block;
        }

        &-value {
          font-size: 14px;
          line-height: 17px;
          margin-left: 5px;
          color: #2D384F;
          display: block;
        }

      }

    }

    &-equipments {
      margin-top: 15px;

      &-element {
        margin-top: 17px;
        flex-direction: row;
        display: flex;
        align-items: flex-start;

        &-icon {
          margin-right: 13px;
          display: block;

          &-microphone {
            width: 13px;
            margin-right: 18px;
          }

          &-lens {
            width: 18px;
          }

          &-cameras {
            width: 18px;
          }


        }

        &-text {

          font-size: 14px;
          line-height: 17px;
          color: #2D384F;

          & b {
            font-family: 'Open Sans Semibold', sans-serif;
            font-weight: normal;
          }

        }

      }

    }

    &-devices {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 17px;

      &-element {
        margin-top: 17px;
        flex-direction: row;
        display: flex;
        align-items: center;
        height: 19px;
        margin-left: 30px;

        &:first-child {
          margin-left: 0;
        }

        &-icon {
          width: 19px;
          height: 19px;
          margin-right: 10px;
        }

        &-text {
          font-size: 14px;
          line-height: 17px;
          display: block;
          color: #2D384E;
          font-family: 'Open Sans Semibold', sans-serif;
        }

      }

    }

    &-skills {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &-element {
        margin-top: 22px;
        margin-right: 6px;
        background-color: #F2F2F2;
        border-radius: 20px;
        padding: 3px 12px;
        font-size: 14px;
        line-height: 17px;
        color: #2D384F;

        &-text {
          font-size: 14px;
          line-height: 17px;
          color: #2D384F;

          & b {
            font-family: 'Open Sans Semibold', sans-serif;
          }

        }

      }

    }

  }

  &-right {
    width: calc(100% - 250px);
    top: -41px;
    position: relative;

    @include respond-to(700px) {
      width: 100%;
    }

    &-content {
      display: flex;
      flex-direction: row;

      @include respond-to(1100px) {
        flex-direction: column;
      }

      &-col {
        width: 60%;
        border-right: 1px solid #E5E5E5;

        @include respond-to(1100px) {
          width: 100%;
        }


        &:last-child {
          width: 40%;
          border-right-width: 0;

          @include respond-to(1100px) {
            width: 100%;
          }

        }

      }

    }

  }

  &-top {
    display: flex;
    flex-direction: row;
    align-content: center;
    padding: 20px 32px 45px;
    border-bottom: 1px solid #E5E5E5;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    @include respond-to(700px) {
      padding: 12px;
    }

    &-dots {
      position: absolute;
      right: 28px;
      top: 18px;

      &-icon {
        width: 6px;
        display: block;
        cursor: pointer;
      }

      &-submenu {
        position: absolute;
        right: 0;
        width: 170px;
        top: 30px;
        background: #FFFFFF;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
        border-radius: 4px;

        &-element {
          display: flex;
          align-items: center;
          height: 30px;
          padding-left: 23px;
          padding-right: 10px;
          font-size: 14px;
          line-height: 17px;
          color: #2D384F;
          cursor: pointer;

          &:hover {
            background: rgba(42, 142, 254, 0.2);
          }

        }

      }

    }

    &-avatar {
      width: 104px;
      height: 104px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      border: 1px solid #E5E5E5;
      border-radius: 104px;
      box-sizing: border-box;

      @include respond-to(700px) {
        width: 55px;
        height: 55px;
        border-radius: 55px;
      }


      &-close {
        width: 30px;
        height: 30px;
        background-color: #FFFFFF;
        display: flex;
        border: 1px solid #E5E5E5;
        border-radius: 44px;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        color: $blue;
        cursor: pointer;
        position: absolute;
        bottom: -3px;
        right: -4px;
      }

      &-edit {
        width: 44px;
        height: 44px;
        cursor: pointer;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url(../../public/images/circle-edit-icon.svg);
        position: absolute;
        top: -3px;
        left: -16px;
      }

    }

    &-pro-link {
      margin-left: 33px;
      height: 20px;
      flex-direction: row;
      display: flex;
      align-items: center;
      margin-top: 5px;

      &-icon {
        width: 21px;
        display: block;
      }

      &-label {
        margin-left: 16px;
        font-size: 20px;
        line-height: 20px;
        color: $blue;
        margin-top: 4px;
        font-family: 'Open Sans Bold', sans-serif;
      }

    }

    &-info {
      margin-left: 21px;

      @include respond-to(700px) {
        margin-left: 12px;
      }

      &-name {
        display: block;
        font-size: 25px;
        line-height: 30px;
        font-family: 'Open Sans Bold', sans-serif;
        color: #2D384E;

        @include respond-to(700px) {
          font-size: 16px;
          line-height: 1.2;
        }

      }

      &-location {
        display: block;
        margin-top: 13px;
        font-size: 16px;
        line-height: 19px;
        color: #798492;
        font-family: 'Open Sans Light', sans-serif;

        @include respond-to(700px) {
          font-size: 14px;
          line-height: 1.2;
          margin-top: 6px;
        }

        & i {
          color: #A2A2A2;
          margin-right: 8px;
          display: inline-block;

          @include respond-to(700px) {
            margin-right: 4px;
          }

        }

      }

      &-stars {
        display: block;
        margin-top: 16px;
        flex-direction: row;
        align-items: center;

        @include respond-to(700px) {
          margin-top: 6px;
        }
      }

      &-star {
        width: 19px;
        height: 19px;
        margin-left: 1px;

        @include respond-to(700px) {
          width: 12px;
          height: 12px;
        }

        &:first-child {
          margin-left: 0;
        }

      }

    }

  }

}

.portfolio-modal {
  width: 650px;
  height: 400px;
  margin: auto;
  padding: 43px 45px 0;
  box-sizing: border-box;

  &-fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1900;
    background: rgba(255,255,255,.2);
  }

  &-close {
    width: 17px;
    height: 17px;
    cursor: pointer;
    display: block;
    background-image: url(../../public/images/close.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 14px;
    right: 15px;
  }

  &-title {
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: #2D384F;
    font-family: 'Open Sans Bold', sans-serif;
  }

  &-content {
    height: 260px;
    overflow-y: auto;
  }

  &-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    &-inner {
      margin-left: auto;
      display: flex;
      flex-direction: row;
    }

  }

  &-reject-button {
    height: 30px;
    padding-left: 29px;
    padding-right: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border: 1px solid rgba(121, 132, 146, .5);
    box-sizing: border-box;
    border-radius: 30px;
    background-color: #FFFFFF;
    font-size: 16px;
    color: #979797;
  }

  &-submit-button {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 29px;
    padding-right: 29px;
    margin-left: 31px;
    background: $blue;
    border-radius: 30px;
    border: 1px solid $blue;
    font-size: 16px;
    color: #FFFFFF;
  }

  &-text {
    margin-top: 14px;
    display: block;
    font-size: 12px;
    line-height: 15px;
    color: #2D384F;
  }

  &-elements {
    margin-top: 8px;
  }

  &-element {
    margin-top: 15px;
    font-size: 14px;
    line-height: 17px;
    display: block;
    color: #2D384F;

    &-label {
      color: #878787;
    }

    & a {
      color: $blue;
    }

    &-icon {
      display: inline-block;
      width: 17px;
      height: 17px;
      position: relative;
      top: 4px;
    }

  }

}

.stringer-warning-block {
  height: 88px;
  width: 100%;
  background-color: rgba(42, 142, 254, 0.13);
  margin-bottom: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 35px;
  box-sizing: border-box;

  &-icon {
    width: 39px;
    height: 39px;
    border-radius: 39px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../public/images/warning-circle.svg);
  }

  &-text {
    margin-left: 29px;
    font-size: 16px;
    line-height: 19px;
    color: #2D384F;
    font-family: 'Open Sans Semibold', sans-serif;
  }

  &-buttons {
    margin-left: auto;
    flex-direction: row;
    display: flex;
  }

  &-button {
    width: 100px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 16px;
    line-height: 16px;

    &.accept {
      color: #FFFFFF;
      background-color: $blue;
      border: 1px solid $blue;
      margin-left: 20px;
    }

    &.decline {
      color: $blue;
      background-color: transparent;
      border: 1px solid $blue;
    }

  }

}

.page {

  padding: 18px 20px 90px;
  box-sizing: border-box;

  &-inner {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
    box-sizing: border-box;
  }

}

.lds-ripple {
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid $blue;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.portfolio-modal {
  width: 650px;
  height: 400px;
  margin: auto;
  padding: 43px 45px 0;
  box-sizing: border-box;

  &-fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1900;
    background: rgba(255,255,255,.5);
  }

  &-close {
    width: 17px;
    height: 17px;
    cursor: pointer;
    display: block;
    background-image: url(../../public/images/close.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 14px;
    right: 15px;
  }

  &-title {
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: #2D384F;
    font-family: 'Open Sans Bold', sans-serif;
  }

  &-content {
    height: 260px;
    overflow-y: auto;
  }

  &-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    &-inner {
      margin-left: auto;
      display: flex;
      flex-direction: row;
    }

  }

  &-reject-button {
    height: 30px;
    padding-left: 29px;
    padding-right: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    border: 1px solid rgba(121, 132, 146, .5);
    box-sizing: border-box;
    border-radius: 30px;
    background-color: #FFFFFF;
    font-size: 16px;
    color: #979797;
  }

  &-submit-button {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 29px;
    padding-right: 29px;
    margin-left: 31px;
    background: $blue;
    border-radius: 30px;
    border: 1px solid $blue;
    font-size: 16px;
    color: #FFFFFF;
  }

  &-text {
    margin-top: 14px;
    display: block;
    font-size: 12px;
    line-height: 15px;
    color: #2D384F;
  }

  &-elements {
    margin-top: 8px;
  }

  &-element {
    margin-top: 15px;
    font-size: 14px;
    line-height: 17px;
    display: block;
    color: #2D384F;

    &-label {
      color: #878787;
    }

    &-icon {
      display: inline-block;
      width: 17px;
      height: 17px;
      position: relative;
      top: 4px;
    }

  }

}

#root {
  width: 100%;
  height: 100%;
}

.camera-bg {
  background-image: url(../../public/images/camera-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}

.activation-window {
  width: 480px;
  height: 540px;

  &-top {
    background-color: $blue;
    height: 200px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-icon {
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../../public/images/email-confirmation.svg);
      width: 124px;
      height: 124px;
    }

  }

  &-content {
    padding: 40px 30px;
    box-sizing: border-box;
  }

  &-title {
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: 33px;
    text-transform: uppercase;
    color: #2D384F;
    font-family: 'Open Sans Bold', sans-serif;
  }

  &-text {
    display: block;
    margin-top: 19px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #2D384F;

    & b {
      font-family: 'Open Sans Bold', sans-serif;
    }

  }

  &-button {
    margin: 36px auto 0;
    width: 300px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    background-color: $blue;
    border-radius: 30px;
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
    font-family: 'Open Sans Semibold', sans-serif;
    border: 1px solid $blue;
  }

  &-logout {
    display: block;
    text-decoration: underline;
    margin-top: 25px;
    font-size: 14px;
    line-height: 17px;
    color: #2D384F;
    text-align: center;
    cursor: pointer;
  }

  &-close {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../public/images/close-white.svg);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 15px;
    right: 18px;
    cursor: pointer;
  }

}

.auth-window {
  width: 480px;
  background: #FFFFFF;
  padding: 30px 45px;
  box-sizing: border-box;
  height: auto;

  &-forgot-password {
    display: flex;
    padding-top: 10px;
    flex-direction: row;
    align-items: center;

    &-link {
      margin-left: auto;
      color: $blue;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

    }

  }

  @include respond-to(700px) {
    width: calc(100vw - 20px);
    padding: 33px 22px 15px;
  }

  &-fade {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 1900;
  }

  &-close {
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../public/images/close.svg);
    width: 24px;
    height: 24px;
    position: absolute;
    top: 15px;
    right: 18px;
    cursor: pointer;
  }

  &-title {
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 35px;
    line-height: 43px;
    text-transform: uppercase;
    color: #2D384F;

    @include respond-to(700px) {
      font-size: 22px;
      line-height: 1.2;
    }

  }

  &-sub-title {
    display: block;
    text-align: center;
    margin-top: 3px;
    font-size: 17px;
    line-height: 21px;
    color: #2D384F;

    @include respond-to(700px) {
      font-size: 14px;
      line-height: 1.2;
    }

  }

  &-elements {
    margin-top: 28px;
  }

  &-element {
    margin-top: 17px;

    &:first-child {
      margin-top: 0;
    }

  }

  &-checkbox {
    margin-top: 36px;
  }

  &-button {
    margin-top: 25px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    background-color: $blue;
    border-radius: 30px;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #FFFFFF;
    font-family: 'Open Sans Semibold', sans-serif;
    border: 1px solid $blue;
  }

  &-line {
    position: relative;
    margin-top: 35px;
    height: 1px;
    background-color: #E5E5E5;
    width: calc(100% + 90px);
    margin-left: -45px;

    &-text {
      font-size: 18px;
      line-height: 22px;
      color: #2D384F;
      background-color: #FFFFFF;
      text-align: center;
      display: block;
      position: absolute;
      top: -11px;
      left: calc(50% - 32px);
      width: 64px;
    }

  }

  &-social-icons {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-social-icon {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;

    &.facebook {
      background-image: url(../../public/images/social/facebook.svg);
    }

    &.linkedin {
      background-image: url(../../public/images/social/linkedin.svg);
    }

    &.google {
      background-image: url(../../public/images/social/google.svg);
    }

  }

  &-social-text {
    margin-top: 25px;
    display: block;
    text-align: center;
    font-size: 18px;
    line-height: 22px;
    color: #2D384F;
  }

}

.site-panel-header {
  height: 30px;
  background-color: $blue;
  width: 100%;

  &-element {
    color: #FFFFFF;
    font-size: 15px;
    text-decoration: none;
    margin-right: 50px;

    &.active {
      font-family: 'Open Sans Semibold', sans-serif;
    }

    &:hover {
      opacity: .7;
    }

  }

  &-container {
    max-width: 1280px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    box-sizing: border-box;
    padding-left: 264px;

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
      padding-left: 0;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(800px) {
      max-width: 680px;
    }

    @include respond-to(700px) {
      width: 100%;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
    }

  }

}

.site-header {
  width: 100%;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0 1px 10px 5px rgba(184, 184, 184, 0.25);
  margin-left: auto;
  z-index: 100;

  &-menu-bar {
   display: none;

    @include respond-to(700px) {
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      border-left: 1px solid #E5E5E5;
    }

    &-icon {
      height: 30px;
      width: 30px;
    }

  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  @include respond-to(800px) {
    height: 50px;
  }

  &-user {
    margin-left: auto;
    position: relative;

    @include respond-to(800px) {
      position: absolute;
      top: 0;
      right: 50px;
    }


    &-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 42px;
      justify-content: center;

      @include respond-to(800px) {
        width: 50px;
        height: 50px;
        align-items: center;
        box-sizing: border-box;
        border-left: 1px solid #E5E5E5;
      }

      &-image {
        width: 37px;
        box-shadow: 0 0 3px rgba(0,0,0,.3);
        height: 37px;
        display: block;
        border-radius: 30px;

        @include respond-to(800px) {
          height: 25px;
          width: 25px;
          border-radius: 25px;
        }

      }

      &-arrow {
        height: 6px;
        display: block;
        margin-left: 5px;

        @include respond-to(800px) {
          display: none;
        }
      }

      &-name {
        margin-left: 10px;
        font-size: 15px;
        color: #2D384E;
        display: block;

        @include respond-to(800px) {
          display: none;
        }

      }

    }

    &-menu {
      position: absolute;
      z-index: 100;
      top: 37px;
      right: 0;
      width: 100%;
      min-width: 100px;
      display: none;
      padding-top: 12px;

      @include respond-to(800px) {
        width: 120px;
      }

      &-inner {
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
      }

      &-element {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding: 6px 10px 6px 23px;
        font-size: 14px;
        line-height: 17px;
        color: #2D384F;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: rgba(42, 142, 254, 0.2);
        }

      }

    }

    &:hover {

      & .site-header-user-menu {
        display: block;
      }

    }

  }

  &-container {
    max-width: 1280px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(800px) {
      max-width: 680px;
    }

    @include respond-to(700px) {
      width: 100%;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
    }

  }

  &-logo {
    display: block;
    height: 40px;

    @include respond-to(800px) {
      width: 130px;
      display: block;
      height: auto;
    }

    & img {
      width: 100%;
      height: 100%;
      max-width: 180px;
    }

  }

  &-menu {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-left: 52px;

    @include respond-to(1100px) {
      margin-left: 32px;
    }

    @include respond-to(800px) {
      display: none;
    }

    & li {
      margin-left: 47px;

      &:first-child {
        margin-left: 0;
      }

      & a {
        font-size: 15px;
        line-height: 18px;
        color: #2D384E;
        text-decoration: none;

        &:hover {
          color: $blue;
        }

        &.active {
          color: $blue;
          font-family: 'Open Sans Semibold', sans-serif;
        }

      }

    }

  }

  &-right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-login-link {
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: underline;
    margin-right: 23px;
    color: $blue;
    cursor: pointer;
    transition: 0.3s;

    @include respond-to(800px) {
      display: none;
    }

    &:hover {
      opacity: .5;
    }

  }

  &-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $blue;
    background-color: #FFFFFF;
    color: $blue;
    box-sizing: border-box;
    border-radius: 30px;
    padding-left: 16px;
    padding-right: 16px;
    height: 37px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;

    @include respond-to(1100px) {
      display: none;
    }

    &-icon {
      margin-right: 10px;
      font-size: 18px;
    }

    &:hover {
      background-color: $blue;
      color: #FFFFFF;

    }

  }

  &-register-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $blue;
    box-sizing: border-box;
    border-radius: 30px;
    width: 143px;
    height: 37px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
    background-color: $blue;
    color: #FFFFFF;

    @include respond-to(800px) {
      display: none;
    }

    &:hover {
      background-color: #FFFFFF;
      color: $blue;
    }

  }

}

.site-page {
  min-height: calc(100vh - 130px);

  &-container {
    max-width: 1280px;
    margin: auto;
    min-height: calc(100vh - 110px - 80px);
    padding-top: 40px;
    padding-bottom: 40px;

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(800px) {
      max-width: 680px;
    }

    @include respond-to(700px) {
      width: 100%;
      box-sizing: border-box;
      padding: 20px 12px;
    }

  }

  &-title {
    display: block;
    text-align: center;
    font-size: 30px;
    line-height: 37px;
    text-transform: uppercase;
    color: #2D384F;
    font-family: 'Open Sans Bold', sans-serif;
  }

  &-sub-title {
    display: block;
    text-align: center;
    margin-top: 9px;
    font-size: 17px;
    line-height: 21px;
    color: #2D384F;
  }

}

.select-roles {
  max-width: 900px;
  margin: 46px auto 0;
  box-sizing: border-box;
}

.select-role {

  width: 430px;
  background-color: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-radius: 5px;
  float: left;
  padding: 35px 50px 45px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:first-child {
    margin-right: 40px;
  }

  &-icon {
    width: 81px;
    height: 81px;
    border-radius: 81px;
    background-color: $blue;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-size: 18px;
  }

  &-title {
    font-size: 18px;
    line-height: 22px;
    display: block;
    text-align: center;
    color: #2D384F;
    margin-top: 18px;
    font-family: 'Open Sans Semibold', sans-serif;
  }

  &-text {
    margin-top: 30px;
    text-align: center;
    font-size: 14px;
    line-height: 17px;
    color: #2D384F;

    & p {

      &:first-child {
        margin-top: 0;
      }

    }

  }

  & ul {
    padding: 0;
    margin: 23px 0 0;
    font-size: 14px;
    line-height: 17px;
    color: #2D384F;
  }

  &-button {
    width: 143px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 34px;
    box-shadow: none;
    background-color: $blue;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    font-family: 'Open Sans Semibold', sans-serif;
    border-width: 0;
    cursor: pointer;
  }

}

.site-copyright {
  width: 100%;
  height: 60px;
  background-color: #212339;

  &-container {
    max-width: 1280px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(800px) {
      max-width: 680px;
    }

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(700px) {
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
    }

  }

  &-text {
    font-size: 14px;
    line-height: 17px;
    color: #505A74;

    @include respond-to(700px) {
      font-size: 12px;
      line-height: 1.2;
      text-align: center;
      display: block;
      width: 100%;
    }

  }

  &-links {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0;

    @include respond-to(700px) {
      display: none;
    }

    & li {
      margin-left: 30px;

      &:first-child {
        margin-left: 0;
      }

      & a {
        text-decoration: none;
        font-size: 14px;
        line-height: 17px;
        color: #505A74;
      }

    }

  }

}

.complete-profile {

  &-inner {
    max-width: 700px;
    margin: 46px auto 0;
    box-sizing: border-box;
  }

  &-row {
    margin-top: 21px;

    &:first-child {
      margin-top: 0;
    }

  }

  &-col {
    width: calc(50% - 15px);
    float: left;

    &:nth-child(1) {
      margin-right: 15px;
    }

    &:nth-child(2) {
      margin-left: 15px;
    }

  }

  &-bottom {

    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 60px;

    &-right {
      margin-left: auto;
      display: flex;
      flex-direction: row;
    }

    &-cancel-button {
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      border: 1px solid rgb(121, 132, 146, .5);
      height: 40px;
      background-color: #FFFFFF;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 20px;
      line-height: 24px;
      color: #798492;
      font-family: 'Open Sans Semibold', sans-serif;
      cursor: pointer;
    }

    &-submit-button {
      margin-left: 44px;
      background-color: $blue;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      border: 1px solid $blue;
      height: 40px;
      padding-left: 30px;
      padding-right: 30px;
      font-size: 20px;
      line-height: 24px;
      color: #FFFFFF;
      font-family: 'Open Sans Semibold', sans-serif;
      cursor: pointer;
    }

  }


}

.stringer-page {

  &-rating-empty {
    padding-top: 80px;
    padding-bottom: 80px;

    @include respond-to(700px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    &-icon {
      width: 251px;
      height: 209px;
      background-repeat: no-repeat;
      background-size: cover;
      margin: auto;
      background-image: url(../../public/images/rating-empty.svg);

      @include respond-to(700px) {
        width: 180px;
        height: 150px;
      }
    }

    &-text {
      margin-top: 50px;
      font-size: 18px;
      line-height: 22px;
      color: #000000;
      display: block;
      text-align: center;

      @include respond-to(700px) {
        margin-top: 24px;
        font-size: 14px;
        line-height: 1.2;
      }

    }

  }

  &-block {

    &-edit-icon {
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-left: 10px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../../public/images/circle-edit-icon.svg);
    }

    &-empty {
      padding-bottom: 10px;

      &-icon {
        width: 110px;
        height: 110px;
        border-radius: 110px;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 20px auto 0;

        &-skills {
          background-image: url(../../public/images/skill_empty.svg);
        }

        &-equipments {
          background-image: url(../../public/images/equipments-icon.svg);
        }

      }

      &-text {
        display: block;
        text-align: center;
        margin-top: 16px;
        font-size: 18px;
        line-height: 22px;
        color: #000000;
      }

      &-link {
        display: block;
        text-align: center;
        margin-top: 9px;
        font-size: 14px;
        line-height: 17px;
        text-decoration-line: underline;
        color: $blue;
        cursor: pointer;
      }

    }

  }

  &-pro-block {

    padding-top: 45px;
    padding-bottom: 45px;

    &-icon {
      width: 90px;
      height: 90px;
      border-radius: 90px;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../../public/images/pro-icon.svg);
      margin: auto;
    }

    &-status {
      display: block;
      text-align: center;
      margin-top: 16px;
      font-size: 16px;
      line-height: 20px;
      font-family: 'Open Sans Semibold', sans-serif;
      color: $blue;
    }

    &-status-text {
      display: block;
      text-align: center;
      margin-top: 16px;
      font-size: 12px;
      line-height: 15px;
      color: #525252;
    }

    &-text {
      margin-top: 16px;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #2D384F;
      display: block;
    }

    &-button {
      margin: 26px auto 0;
      width: 164px;
      height: 33px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue;
      border-width: 0;
      border-radius: 30px;
      box-shadow: none;
      font-size: 16px;
      line-height: 19px;
      font-family: 'Open Sans Semibold', sans-serif;
      color: #FFFFFF;
      cursor: pointer;
    }

  }

}

.modal-window {

  &-fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 20000;
  }

  background-color: #FFFFFF;
  width: 600px;
  height: 600px;

  @include respond-to(700px) {
    width: 100vw;
  }

  &.small {
    width: 300px;
    height: auto;
  }

  &-row {
    margin-top: 21px;

    &:first-child {
      margin-top: 0;
    }

  }

  &-col {
    width: calc(50% - 15px);
    float: left;

    &:nth-child(1) {
      margin-right: 15px;
    }

    &:nth-child(2) {
      margin-left: 15px;
    }

  }

  &-checkboxes {
    margin-top: 16px;
  }

  &-checkbox {
    margin-top: 12px;
    float: left;
    width: 33.33%;
  }

  &-top {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #E5E5E5;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
    width: 100%;

    &-title {
      font-size: 18px;
      line-height: 22px;
      font-family: 'Open Sans Bold', sans-serif;
      color: #2D384F;
      user-select: none;
    }

    &-close {
      margin-left: auto;
      width: 24px;
      height: 24px;
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(../../public/images/close.svg);
    }

  }

  &-content {
    width: 100%;
    height: 480px;
    box-sizing: border-box;

    &.small {
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 30px;
      height: auto;
    }

    &-inner {
      padding: 20px 30px;
      box-sizing: border-box;
      overflow-y: auto;
      width: 100%;
      height: 100%;
    }

  }

  &-bottom {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid #E5E5E5;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    box-sizing: border-box;

    &-buttons {
      margin-left: auto;
      display: flex;
      flex-direction: row;
      height: 100%;
      align-items: center;
    }

    &-submit-button {
      height: 30px;
      padding-left: 20px;
      padding-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue;
      border: 1px solid $blue;
      box-sizing: border-box;
      border-radius: 30px;
      font-size: 16px;
      line-height: 20px;
      color: #FFFFFF;
      margin-left: 23px;
    }

    &-cancel-button {
      padding-left: 20px;
      padding-right: 20px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      border: 1px solid rgba(121, 132, 146, .5);
      box-sizing: border-box;
      border-radius: 30px;
      font-size: 16px;
      line-height: 20px;
      color: #798492;
    }


  }


}

.custom-multiply-fields {

  &-element {
    display: flex;
    flex-direction: row;
    margin-top: 13px;
    align-items: center;

    &-add-icon {
      margin-left: auto;
      width: 20px;
      height: 20px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      background-image: url(../../public/images/circle-gray.svg);
    }

    &-delete-icon {
      margin-left: auto;
      height: 19px;
      width: 14.79px;
      position: relative;
      right: 3px;
      display: block;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      background-image: url(../../public/images/circle-trash.svg);
    }

    & .custom-text-field-input {
      width: calc(100% - 33px);
    }

  }

}

.video-files {

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 40px;
  box-sizing: border-box;

  @include respond-to(800px) {
    min-height: auto;
  }

  &-upload-button {
    height: 41px;
    display: flex;
    padding-left: 53px;
    cursor: pointer;
    margin: 34px auto 0;
    align-items: center;
    background-image: url(../../public/images/upload-plus.svg);
    background-repeat: no-repeat;
    font-size: 14px;
    line-height: 16px;
    font-family: 'Open Sans Semibold', sans-serif;
    color: $blue;
  }

  &-empty {

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 40px 20px 0;
    box-sizing: border-box;

    &-text {
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      color: #2D384F;
      width: 270px;
      display: block;
    }

    &-button {
      margin-top: 18px;
      width: 153px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue;
      border-radius: 6px;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
      font-family: 'Open Sans Semibold', sans-serif;
      border: 0;
      box-shadow: none;
      transition: all .2s ease-in-out;

      &:hover {
        opacity: .7;
      }

    }

  }

}

.pro-modal-window {

  &-fade {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 20000;
  }

  &-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../../public/images/close.svg);
  }

  &-icon {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    margin: 0 auto 60px;
    position: relative;
    top: 30px;
    background-image: url(../../public/images/pro-icon.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-status {
    margin-top: 16px;
    text-align: center;
    font-size: 18px;
    display: block;
    line-height: 22px;
    color: $blue;
    font-family: 'Open Sans Semibold', sans-serif;
  }

  &-elements {
    margin: 20px auto 0;
    width: 270px;
  }

  &-element {
    margin-top: 15px;
    display: flex;
    align-items: center;

    &-icon {
      width: 19px;
      height: 19px;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 14px;

      &.completed {
        background-image: url(../../public/images/check.svg);
      }

      &.not-completed {
        background-image: url(../../public/images/not-completed.png);
      }

    }

    &-label {
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    &:first-child {
      margin-top: 0;
    }

  }

  &-text {
    display: block;
    text-align: center;
    margin-top: 27px;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
  }

  &-buttons {
    margin-top: 27px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &-button {
    width: 143px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-radius: 30px;
    font-size: 20px;
    line-height: 24px;
    box-shadow: none;
    position: relative;

    &.submit {
      color: #ffffff;
      font-family: 'Open Sans Semibold', sans-serif;
      background-color: $blue;
      border: 1px solid $blue;

      &:disabled {
        background-color: #CFCFCF;
        border: 1px solid #CFCFCF;
      }

    }

    &.cancel {
      color: #798492;
      border: 1px solid rgba(121, 132, 146, .5);
      background-color: #FFFFFF;
    }

    &:nth-child(2) {
      margin-left: 33px;
    }

  }

}

.video-file {

  position: relative;
  width: 100%;
  padding: 8px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #E5E5E5;
  border-left: 1px solid #E5E5E5;
  border-right: 1px solid #E5E5E5;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  &.active {
    background-color: rgba(42, 142, 254, 0.13);
  }

  &.deleted {
    background-color: rgba(255, 0, 0, 0.13);
  }

  &:first-child {
    border-top: 1px solid #E5E5E5;
  }

  &-upload {
    width: 100%;
    height: 64px;
    padding: 8px 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #E5E5E5;
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;

    &:first-child {
      border-top: 1px solid #E5E5E5;
    }

    &-top {
      display: flex;
      flex-direction: row;
    }

    &-title {
      font-size: 14px;
      line-height: 16px;
      font-family: 'Open Sans Semibold', sans-serif;
      color: #2D384E;
    }

    &-close {
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: pointer;
      background-image: url(../../public/images/close.svg);
      margin-left: auto;
    }

    &-line {
      width: 100%;
      height: 6px;
      position: relative;
      background-color: rgba(172, 177, 190, 0.47);
      border-radius: 10px;
      margin-top: 6px;

      &-active {
        position: absolute;
        left: 0;
        top: 0;
        background-color: $blue;
        border-radius: 10px;
        height: 6px;
      }

    }

    &-bottom {
      display: flex;
      flex-direction: row;
      margin-top: 7px;
    }

    &-size {
      font-size: 12px;
      line-height: 15px;
      color: #798492;
    }

    &-percent {
      font-size: 12px;
      line-height: 15px;
      color: #798492;
      margin-left: auto;
    }

  }

  &-icons {
    position: absolute;
    right: 10px;
    top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-arrows {
    position: absolute;
    right: 10px;
    bottom: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;

    & i {
      color: $blue;
      margin-left: 2px;
      margin-right: 2px;
    }

  }

  &-image {
    width: 89px;
    height: 54px;
    position: relative;
    border-radius: 7px;
    margin-right: 15px;
  }

  &-content {
    padding-right: 56px;
    box-sizing: border-box;
    width: 100%;
  }

  &-icon {
    color: $blue;
    margin-left: 2px;
    margin-right: 2px;

    &:first-child {
      margin-left: 0;
    }


  }

  &-name {
    font-size: 12px;
    line-height: 14px;
    color: #2D384F;
    font-family: 'Open Sans Semibold', sans-serif;
    display: block;
    width: calc(100% - 70px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &-size {
    font-size: 10px;
    line-height: 12px;
    color: #2D384F;
    font-family: 'Open Sans Semibold', sans-serif;
    display: block;
    margin-top: 3px;
  }

}

.video-page {

  box-sizing: border-box;
  padding: 18px 20px 90px;

  &-filter-icon {
    display: none;

    @include respond-to(800px) {
      display: flex;
      margin-left: auto;
      height: 30px;
      width: 30px;
      align-items: center;
      justify-content: center;
      background-color: $blue;
      border-radius: 8px;
      color: #FFFFFF;
      font-size: 14px;
      cursor: pointer;
    }

  }

  &-loading {
    position: relative;
    width: 100%;
    height: calc(100vh - 163px);
  }

  &-fixing-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 24px;
    display: block;
    cursor: pointer;
    background-image: url(../../public/images/action-copy.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-change-owner-button {
    margin-top: 20px;
  }

  &-player {
    border-radius: 7px;
    background-color: #E1E1E1;
    margin-top: 13px;
    position: relative;
    border: 1px solid #E5E5E5;

    &-fixed {
      position: fixed;
      right: 70px;
      bottom: 70px;
      width: 700px;
      z-index: 2000;
    }

  }

  &-element {
    margin-top: 13px;
    width: 100%;
  }

  &-block {

    &-title {
      color: #2D384F;
      font-family: 'Open Sans Bold', sans-serif;
      font-size: 16px;
      line-height: 19px;
    }

  }

  &-inner {
    background: #FFFFFF;
    width: 100%;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 25px;
  }

  &-top {

    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: row;

    &-back-icon {
      margin-right: 14px;
      position: relative;
      height: 24px;
      width: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      box-shadow: none;
      border-width: 1px;
      border-style: solid;
      border-radius: 4px;
      transition: all 0.2s ease-out;
      outline: none;
      color: #979797;
      text-decoration: none;
      font-size: 20px;
      cursor: pointer;
      background-color: #ffffff;
      border-color: #979797;

      @include respond-to(700px) {
        width: 16px;
        height: 16px;
        margin-right: 12px;
      }

      &:hover {
        opacity: .7;
      }

    }

    &-title {
      color: #2D384F;
      font-size: 22px;
      line-height: 27px;
      font-family: "Open Sans Bold", sans-serif;
      text-transform: uppercase;

      @include respond-to(700px) {
        font-size: 17px;
        line-height: 20px;
      }


    }

    &-right {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      @include respond-to(800px) {
        & .table-page-paginations {
          display: none;
        }
      }

    }

    &-accept {
      margin-left: 20px;
    }

    &-hold {
      margin-right: 20px;
    }

  }


  &-files {
    width: 30%;
    padding: 20px 26px 21px;
    box-sizing: border-box;
    float: left;
    position: relative;

    &-elements {
      margin-top: 13px;
    }

  }

  &-right {
    width: 25%;
    box-sizing: border-box;
    float: left;
  }

  &-sellings {
    margin-top: 27px;
    position: relative;
  }

  &-selling {
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 7px;
    margin-top: 8px;
    height: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;

    &-add-icon {
      position: absolute;
      left: 80px;
      top: -49px;
      height: 20px;
      display: flex!important;
      padding: 0!important;
      flex-direction: row;
      width: 20px;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      border-width: 1px;
      font-size: 15px;
      border-style: solid;
      border-radius: 24px;
      transition: all 0.2s ease-out;
      outline: none;
      text-decoration: none;
      background-color: #2a8eff;
      border-color: #2a8eff;
      color: #FFFFFF;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

    }

    &:first-child {
      margin-top: 0;
    }

    &-date {
      font-size: 14px;
      color: #2D384E;
    }

    &-price {
      font-size: 14px;
      font-family: "Open Sans Semibold", sans-serif;
      color: #2D384E;
      margin-left: 7px;
    }

    &-delete {
      margin-left: auto;
      position: relative;
      height: 24px;
      display: flex;
      flex-direction: row;
      width: 24px;
      align-items: center;
      justify-content: center;
      box-shadow: none;
      border-width: 1px;
      font-size: 12px;
      border-style: solid;
      border-radius: 24px;
      transition: all 0.2s ease-out;
      outline: none;
      text-decoration: none;
      background-color: #2a8eff;
      border-color: #2a8eff;
      color: #FFFFFF;
      cursor: pointer;

      &:hover {
        opacity: .7;
      }

    }

  }

  &-partners {
    width: 100%;
    padding: 20px 26px 21px;
    box-sizing: border-box;

    &-empty {
      display: block;
      margin-top: 20px;

      &-text {
        display: block;
        font-size: 14px;
      }

    }

    &-elements {
      margin-top: 27px;
    }

    &-element {
      width: 100%;
      background-color: #FFFFFF;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 7px;
      margin-top: 8px;

      &:first-child {
        margin-top: 0;
      }

      &-button {
        margin-left: auto;
        position: relative;
        height: 24px;
        display: flex;
        flex-direction: row;
        padding-left: 12px;
        padding-right: 12px;
        align-items: center;
        box-shadow: none;
        border-width: 1px;
        font-size: 12px;
        border-style: solid;
        border-radius: 24px;
        transition: all .2s ease-out;
        outline: none;
        text-decoration: none;
        background-color: $blue;
        border-color: $blue;
        color: #FFFFFF;
        cursor: pointer;

        & i {
          margin-right: 4px;
        }

        & .spinner:before {
          left: 70%;
        }

        &.loading {
          position: relative;
          padding-right: 34px;
        }

        &:hover, &:disabled {
          opacity: .6;
        }

      }

      &-top {
        width: 100%;
        height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
        box-sizing: border-box;
      }

      &-name {
        font-size: 14px;
        font-family: 'Open Sans Semibold', sans-serif;
        color: #2D384E;
      }

      &-icon {
        margin-left: auto;
      }

    }

  }

  &-upload {

    &-block {
      width: 100%;
      padding: 20px 26px 21px;
      box-sizing: border-box;
      border-top: 1px solid #E5E5E5;
    }

    &-elements {
      margin-top: 20px;
    }

    &-element {
      display: block;
      font-size: 14px;
      line-height: 17px;
      color: #2D384F;
      word-break: break-all;
      

      &-block {
        margin-top: 10px;

        &-label {
          font-size: 14px;
          line-height: 17px;
          color: #2D384F;
          font-family: 'Open Sans Bold', sans-serif;
        }

        &-text {
          word-break: break-all;
          display: block;
          margin-top: 4px;
          color: #2D384F;
        }

      }

      &-link {
        color: $blue;
        text-decoration: none;
        cursor: pointer;
        word-break: break-all;

        &:hover {
          opacity: 8;
        }

      }

      & b {
        font-family: 'Open Sans Bold', sans-serif;
      }

    }

  }

  &-general-info {
    border-left: 1px solid #E5E5E5;
    border-right: 1px solid #E5E5E5;
    padding: 20px 26px 21px;
    box-sizing: border-box;
    width: 45%;
    float: left;

    &-row {
      display: flex;
      flex-direction: row;
    }

    &-col {

      &:first-child {
        margin-right: 15px;
        width: calc(75% - 15px);
      }

      &:nth-child(2) {
        margin-left: 15px;
        width: calc(25% - 15px);
      }

    }

    &-bottom {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      &-right {
        margin-left: auto;
      }

    }

  }

}

.blue {
  color: $blue;
}

.page-title {
  color: #2D384F;
  font-size: 22px;
  line-height: 27px;
  font-family: "Open Sans Bold", sans-serif;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
}

.videos {
  margin-left: -28px;
  width: calc(100% + 28px);

  @include respond-to(700px) {
    margin-left: -12px;
    width: calc(100% + 12px);
    margin-top: 12px;
  }

}

.video-card {
  padding-left: 28px;
  box-sizing: border-box;
  width: 25%;
  margin-top: 28px;
  float: left;

  @include respond-to(1100px) {
    width: 25%;
  }

  @include respond-to(900px) {
    width: 33.33%;
  }

  @include respond-to(700px) {
    width: 50%;
    padding-left: 12px;
    margin-top: 12px;
  }

  &-inner {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
  }

  &-image {
    position: relative;
    width: 100%;

    &-inner {
      padding-top: 70%;
      height: auto;
      position: relative;
    }

  }

  &-thumb {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #F2F2F2;
    border-radius: 10px 10px 0 0;
  }

  &-title {
    display: block;
    font-family: 'Open Sans Bold', sans-serif;
    text-decoration: none;
    line-height: 20px;
    min-height: 20px;
    height: 60px;
    color: #2D384F;
    overflow-y: hidden;
    text-transform: capitalize;
    font-size: 14px;

    @include respond-to(700px) {
      font-size: 12px;
      line-height: 15px;
      height: 45px;
      min-height: 15px;
    }

  }

  &-content-area {
    padding: 15px;
    box-sizing: border-box;

    @include respond-to(700px) {
      padding: 12px;
    }

  }

  &-content {

    margin-top: 20px;

    &-element {
      margin-top: 8px;

      &-label {
        font-size: 12px;
        width: 80px;
        font-family: 'Open Sans Light', sans-serif;
        color: #8d96a7;
        display: block;
        float: left;
        line-height: 13px;

        @include respond-to(700px) {
          font-size: 11px;
          line-height: 1.2;
        }

      }

      &-value {
        width: calc(100% - 80px);
        float: left;
        font-size: 12px;
        line-height: 13px;
        height: 26px;
        color: #2D384F;
        text-transform: capitalize;
        overflow-y: hidden;
        font-family: 'Open Sans Semibold', sans-serif;

        &.single {
          height: 13px;
        }

        @include respond-to(700px) {
          font-size: 11px;
          line-height: 1.2;
        }

      }

      &:first-child {
        margin-top: 0;
      }

    }

  }

  &-bottom {
    margin-top: 18px;
    height: 26px;
  }

  &-price {
    float: right;
    background-color: $blue;
    color: #ffffff;
    height: 26px;
    line-height: 26px;
    text-align: center;
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12.5px;
    font-size: 15px;
    font-family: 'HelveticaNeueCyr-Medium', sans-serif;
  }

  &-files-num {
    float: right;
    margin-right: 15px;
    margin-top: 3px;

    @include respond-to(700px) {
      margin-right: 0;
    }

    &-icon {
      width: 18px;
      height: 15px;
      background-image: url(../../public/images/files-num.svg);
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      float: left;
    }

    &-value {
      margin-left: 7px;
      font-size: 11px;
      line-height: 14px;
      margin-top: 1px;
      color: #a8acb3;
      float: left;
    }

  }

}

.task-card {
  padding-left: 20px;
  box-sizing: border-box;
  width: 25%;
  margin-top: 20px;
  float: left;

  @include respond-to(800px) {
    width: 50%;
    padding-left: 12px;
    margin-top: 12px;
  }

  &-inner {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #E5E5E5;
    border-radius: 10px;
  }

  &-image {
    position: relative;
    width: 100%;

    &-inner {
      padding-top: 55%;
      height: auto;
      position: relative;
    }

  }

  &-thumb {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #F2F2F2;
    border-radius: 10px 10px 0 0;
  }

  &-title {
    display: block;
    font-family: 'Open Sans Bold', sans-serif;
    text-decoration: none;
    line-height: 17px;
    min-height: 17px;
    height: 34px;
    color: #2D384F;
    overflow-y: hidden;
    text-transform: capitalize;
    font-size: 14px;

    @include respond-to(700px) {
      font-size: 12px;
      line-height: 15px;
      height: 45px;
      min-height: 15px;
    }

  }

  &-content-area {
    padding: 15px;
    box-sizing: border-box;
    height: 170px;

    @include respond-to(700px) {
      padding: 12px;
    }

  }

  &-content {

    margin-top: 20px;

    &-element {
      margin-top: 10px;

      &-label {
        font-size: 12px;
        width: 75px;
        font-family: 'Open Sans Light', sans-serif;
        color: #8d96a7;
        display: block;
        float: left;
        line-height: 12px;
      }

      &-value {
        width: calc(100% - 75px);
        float: left;
        font-size: 12px;
        line-height: 15px;
        max-height: 30px;
        overflow-y: hidden;
        color: #2D384F;
        text-transform: capitalize;
        font-family: 'Open Sans Semibold', sans-serif;
      }

      &:first-child {
        margin-top: 0;
      }

    }

  }

  &-bottom {
    margin-top: 18px;
    height: 26px;
  }

  &-price {
    float: right;
    background-color: $blue;
    color: #ffffff;
    height: 26px;
    line-height: 26px;
    text-align: center;
    display: block;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 12.5px;
    font-size: 15px;
    font-family: 'HelveticaNeueCyr-Medium', sans-serif;
  }

  &-files-num {
    float: right;
    margin-right: 15px;
    margin-top: 3px;

    &-icon {
      width: 24px;
      height: 20px;
      background-image: url(../../public/images/files-num.svg);
      background-repeat: no-repeat;
      background-size: cover;
      display: block;
      float: left;
    }

    &-value {
      margin-left: 10px;
      font-size: 13px;
      line-height: 14px;
      margin-top: 3px;
      color: #a8acb3;
      float: left;
    }

  }

}

.landing-main-banner {
  position: relative;
  width: 100%;

  &-inner {
    padding-top: 50%;
    height: auto;
    position: relative;
    background-image: url(../assets/landing/banner.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @include respond-to(700px) {
      padding-top: 80%;
    }

  }

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      max-width: 1280px;
      margin: auto;
      height: 100%;
    }

  }

  &-title {
    font-size: 45px;
    color: #ffffff;
    line-height: 58px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Open Sans Bold', sans-serif;
    margin: 0;
    padding: 0;

    @include respond-to(700px) {
      font-size: 18px;
      line-height: 1.2;
    }

  }

  &-line {
    display: block;
    height: 6px;
    margin: 39px auto 0;
    background-color: #2a8eff;
    width: 109px;

    @include respond-to(700px) {
      margin-top: 24px;
    }

  }

  &-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 510px;
    margin: 45px auto;
    justify-content: center;

    @include respond-to(700px) {
      width: 100%;
      flex-direction: column;
      margin-top: 12px;
      margin-bottom: 24px;
    }

  }

  &-button {
    font-family: 'Open Sans Semibold', sans-serif;
    height: 44px;
    text-decoration: none;
    text-align: center;
    line-height: 44px;
    float: left;
    display: block;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 30px;
    color: #ffffff;
    border-radius: 27px;
    background-color: transparent;
    box-sizing: border-box;
    width: 240px;
    border: 1px solid #ffffff;
    transition: 0.3s;
    cursor: pointer;

    @include respond-to(700px) {
      height: 36px;
      font-size: 14px;
      line-height: 36px;
      margin-left: 0;
      margin-top: 12px;
      width: 230px;
    }

    &:first-child {
      margin-left: 0;
    }

    &:hover {
      background-color: $blue;
      border-color: $blue;
      color: #FFFFFF;
    }

  }

  &-sub-title {
    display: block;
    text-align: center;
    font-size: 25px;
    line-height: 1.2;
    color: #ffffff;
    text-transform: uppercase;
    font-family: 'Open Sans Bold', sans-serif;

    @include respond-to(700px) {
      font-size: 14px;
      padding-left: 20px;
      padding-right: 20px;
    }

  }

}

.custom-page {
  background-image: url(../assets/landing/background-faq.png);
  background-repeat: no-repeat;
  margin-top: 2px;

  @include respond-to(700px) {
    background: none;
  }

  &-container {
    max-width: 1280px;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 75px;

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(800px) {
      max-width: 680px;
    }

    @include respond-to(700px) {
      width: 100%;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
      padding-top: 24px;
      padding-bottom: 24px;
    }

  }

  &-title {
    font-size: 30px;
    font-family: 'Open Sans Bold', sans-serif;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin: 0;
    padding: 0;

    @include respond-to(700px) {
      font-size: 24px;
      line-height: 1.1;
    }

  }

  &-image {
    width: 40%;
    margin-left: auto;

    @include respond-to(700px) {
      display: none;
    }

    & img {
      width: 345px;
      height: 383px;
    }

  }

  &-content {

    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    @include respond-to(700px) {
      margin-top: 24px;
    }

    &-text {
      width: 50%;
      box-sizing: border-box;
      font-size: 16px;
      color: rgba(0, 0, 0, 0.761);

      @include respond-to(700px) {
        font-size: 12px;
        width: 100%;
      }

      & ol {
        padding: 0;
        margin: 1.5em 0 0 1em;

        & li {
          margin-top: 1em;
        }

      }

    }

  }


}

.faq-block {

  background-image: url(../assets/landing/background-faq.png);
  background-repeat: no-repeat;

  &-container {
    max-width: 1280px;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 75px;

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(800px) {
      max-width: 680px;
    }

    @include respond-to(700px) {
      width: 100%;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
      padding-top: 24px;
      padding-bottom: 24px;
    }

  }

  &-title {
    font-size: 30px;
    font-family: 'Open Sans Bold', sans-serif;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    display: block;

    @include respond-to(700px) {
      font-size: 24px;
      line-height: 1.1;
    }

  }

  &-content {

    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 50px;

    @include respond-to(700px) {
      margin-top: 24px;
    }

    &-image {
      width: 40%;
      margin-left: auto;

      @include respond-to(700px) {
        display: none;
      }

      & img {
        width: 345px;
        height: 383px;
      }

    }

  }

  &-questions {
    width: 50%;
    box-sizing: border-box;

    @include respond-to(700px) {
      width: 100%;
    }

  }

  &-question {

    &-top {
      flex-direction: row;
      height: 73px;
      align-items: center;
      display: flex;
      border-bottom: 1px solid rgba(151, 155, 162, 0.16);
      cursor: pointer;

      @include respond-to(700px) {
        height: auto;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    &-text {
      border-bottom: 1px solid rgba(151, 155, 162, 0.16);
      font-size: 14px;
      color: rgba(0, 0, 0, 0.761);
      padding-bottom: 25px;
      display: block;
      padding-top: 25px;
      max-width: 460px;

      @include respond-to(700px) {
        font-size: 12px;
        width: 100%;
        padding-top: 12px;
      }
    }

    &-label {
      font-size: 18px;
      color: #5a5e65;
      font-family: 'Open Sans Bold', sans-serif;
      user-select: none;

      @include respond-to(700px) {
        font-size: 14px;
      }

      &.active {
        color: $blue
      }

    }

    &-icon {
      margin-left: auto;
      font-size: 18px;
      color: $blue;

      @include respond-to(700px) {
        font-size: 14px;
      }

    }

  }


}

.error-page {

  &-container {
    max-width: 1280px;
    margin: auto;
    padding-top: 75px;
    padding-bottom: 75px;
    min-height: calc(100vh - 100px - 60px - 150px);
  }

  &-resend-button {
    margin: 30px auto 0;
  }

  &-title {
    font-size: 30px;
    font-family: 'Open Sans Bold', sans-serif;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin: 0;
    padding: 0;
  }

  &-fields {
    width: 345px;
    margin: 30px auto;
  }

  &-image {
    width: 345px;
    height: 230px;
    background-image: url(../assets/landing/camera.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;

    &-area {
      width: 345px;
      height: 230px;
      position: relative;
      margin: 45px auto;
      left: 22px;
    }

    &-num {
      display: block;
      color: #000;
      font-size: 190px;
      position: absolute;
      font-family: 'Open Sans Semibold', sans-serif;
      top: 20px;
      line-height: 190px;

      &.left {
        left: -22px;
        z-index: 500;
      }

      &.right {
        right: 22px;
        z-index: 1500;
      }

    }

  }


  &-sub-title {
    display: block;
    text-align: center;
    font-size: 28px;
    font-family: 'Open Sans Bold', sans-serif;
  }

  &-text {
    text-align: center;
    display: block;
    margin-top: 20px;
    font-size: 22px;
    color: #000;
    font-family: 'Open Sans Light', sans-serif;

    & a {
      color: $blue;
      font-family: 'Open Sans Regular', sans-serif;
    }

  }

}

.preview-button {
  margin: 30px auto 10px;
}

.table-page-paginations  {
  margin-left: auto;

  & ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & a {
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;
    height: 30px;
    min-width: 30px;
    box-sizing: border-box;
    padding-right: 4px;
    padding-left: 4px;
    border-radius: 5px;
  }

  & a:hover {
    opacity: .7;
  }

  & li.selected a {
    font-family: 'Open Sans Semibold', sans-serif;
    color: #FFFFFF;
    background-color: $blue;
  }

}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.gallery-filter {

  &-date-range {
    display: flex;
    flex-direction: row;
    margin-top: 10px;

    &-input {
      width: 46%;

      & input {
        width: 100%;
        box-sizing: border-box;
        background: #FFFFFF;
        border: 1px solid rgba(121, 132, 146, 0.5);
        height: 30px;
        padding: 0;
        color: #2D384F;
        font-size: 15px;
        padding-left: 7px;
        padding-right: 7px;
      }

    }

    &-separator {
      width: 8%;
      height: 32px;
      line-height: 32px;
      font-size: 15px;
      display: block;
      text-align: center;
    }

  }

  &-area {
    margin-bottom: 20px;

    &-title {
      font-size: 15px;
      line-height: 18px;
      color: #2D384E;
      font-family: 'Open Sans Semibold', sans-serif;
    }

    &-input {
      position: relative;
      margin-top: 10px;
      width: 100%;

    }

    &-row {
      display: flex;
      flex-direction: row;
    }

  }

  &-apply-button {
    position: relative;
    height: 32px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    font-family: 'Open Sans Semibold', sans-serif;
    padding-left: 12px;
    padding-right: 12px;
    align-items: center;
    left: -2px;
    box-shadow: none;
    border-width: 1px;
    margin-left: auto;
    font-size: 12px;
    border-style: solid;
    width: 60px;
    border-radius: 0 2px 2px 0;
    transition: all 0.2s ease-out;
    outline: none;
    text-decoration: none;
    background-color: #2a8eff;
    border-color: #2a8eff;
    color: #FFFFFF;
    cursor: pointer;
  }

  &-tips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  &-tip {
    margin-right: 10px;
    margin-top: 10px;
    display: block;
    background-color: #EBEBEB;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    color: #8F949F;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      opacity: .7;
    }

    &.active {
      color: #FFFFFF;
      background-color: $blue;
    }

  }

  &-tip-with-close {
    margin-right: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
    background-color: $blue;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;

    &-icon {
      margin-left: 4px;
      display: block;

      &:hover {
        opacity: .7;
      }


    }

  }


}

.gallery-videos {
  display: flex;
  flex-direction: row;
  position: relative;

  &-select {
    position: relative;
    width: 200px;
    margin-left: 20px;

    @include respond-to(1000px) {
      display: none;
    }

  }

  &-filters {

    display: flex;
    flex-direction: row;

    &-tip {
      margin-top: 20px;
      margin-right: 27px;
      height: 32px;
      background-color: #EBEBEB;
      border-radius: 5px;
      padding-left: 17px;
      padding-right: 11px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &-label {
        font-size: 14px;
        line-height: 17px;
        color: #8F949F;
        display: block;
      }

      &-close {
        font-size: 17px;
        line-height: 20px;
        color: #8F949F;
        margin-left: 8px;
        display: block;
      }

    }

  }

  &-search {
    width: 350px;
    position: relative;
    margin-left: 217px;

    @include respond-to(1100px) {
      margin-left: 30px;
    }

    @include respond-to(1000px) {
      display: none;
    }

    &-icon {
      position: absolute;
      left: 10px;
      color: rgba(121, 132, 146, 0.5);
      top: 9px;
    }

    &-input {
      background: #FFFFFF;
      border: 1px solid rgba(121, 132, 146, 0.5);
      border-radius: 2px;
      width: 100%;
      height: 32px;
      box-sizing: border-box;
      padding-left: 30px;
      outline-color: #2a8eff;
      color: #798492;
      font-family: "Open Sans Light", sans-serif;
      font-size: 15px;
    }

  }

  &-left {
    margin-top: 28px;
    position: relative;

    @include respond-to(1100px) {
      display: none;
    }

    &-inner {
      width: 280px;
      border: 1px solid #E5E5E5;
      background-color: #FFFFFF;
      padding: 12px;
      box-sizing: border-box;
      border-radius: 10px;
    }

  }

  &-right {
    margin-left: auto;
    width: calc(100% - 310px);

    @include respond-to(1100px) {
      width: 100%;
    }

  }

}

.gallery {
  background: #FFFFFF;
  width: 100%;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  margin-top: 25px;
  display: flex;
  flex-direction: row;

  @include respond-to(800px) {
    flex-direction: column-reverse;
  }

  @include respond-to(700px) {
    margin-top: 12px;
  }

  &-left {
    border-right: 1px solid #E5E5E5;
    padding: 20px 26px 21px;
    box-sizing: border-box;
    width: 55%;

    @include respond-to(1100px) {
      width: 50%;
    }

    @include respond-to(800px) {
      width: 100%;
    }

    @include respond-to(700px) {
      padding: 12px;
    }

  }

  &-right {
    border-left: 1px solid #E5E5E5;
    width: 45%;
    box-sizing: border-box;
    padding: 20px 26px 21px;

    @include respond-to(1100px) {
      width: 50%;
    }

    @include respond-to(800px) {
      width: 100%;
    }

    @include respond-to(700px) {
      padding: 12px;
      border-left-width: 0;
    }

  }

  &-title {
    font-family: 'Open Sans Semibold', sans-serif;
    color: #2D384E;
    font-size: 24px;
    margin: 0;
    padding: 0;

    @include respond-to(700px) {
      font-size: 16px;
      line-height: 1.1;
    }

  }

  &-link {
    display: block;
    margin-top: 10px;
    font-family: 'Open Sans Semibold', sans-serif;
    color: $blue;
    text-decoration: none;
    font-size: 14px;
  }

  &-elements {
    margin-top: 30px;

    @include respond-to(700px) {
      margin-top: 20px;
    }

  }

  &-element {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: #2D384E;

    &-label {
      width: 140px;

      @include respond-to(700px) {
        width: auto;
      }

    }

    &-value {
      font-family: 'Open Sans Semibold', sans-serif;
      width: calc(100% - 140px);

      @include respond-to(700px) {
        width: auto;
        margin-left: 10px;
      }

    }

    @include respond-to(700px) {
      font-size: 14px;
    }

  }

  &-description {
    margin-top: 20px;
    font-size: 16px;
    color: #2D384E;
    width: 100%;
    overflow: hidden;

    @include respond-to(700px) {
      font-size: 14px;
    }

  }

  &-download-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2a8eff;
    box-sizing: border-box;
    border-radius: 30px;
    height: 37px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    width: 200px;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.3s;
    background-color: #2a8eff;
    color: #FFFFFF;

    &:hover {
      opacity: .8;
    }

  }

  &-files {
    flex-wrap: wrap;
    margin-top: 20px;
    flex-direction: row;
    display: flex;
    gap: 12px;
    width: calc(100% + 12px);
  }

  &-file {
    margin-top: 10px;
    cursor: pointer;
    border-radius: 10px;
    padding: 5px;
    box-sizing: border-box;
    width: calc(25% - 12px);

    &.active {
      background-color: $blue;
    }

    &-preview {
      border-radius: 10px;
      width: 100%;
      display: block;
    }

  }

}

.videos-page-bottom-pagination {
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: row;

  @include respond-to(700px) {
    justify-content: center;
  }

  & .table-page-paginations {
    @include respond-to(700px) {
      margin: auto!important;
    }
  }

}

.videos-block-view {
  margin-left: -28px;
  width: calc(100% + 28px);
  & .video-card {
    width: 20%;
  }

}

.task-tabs {

}

.brief {
  background: #FFFFFF;
  width: 100%;
  box-shadow: 2px 2px 10px rgb(0 0 0 / 10%);
  margin-top: 25px;
  display: flex;
  flex-direction: row;

  @include respond-to(800px) {
    flex-direction: column-reverse;
  }

  @include respond-to(700px) {
    margin-top: 12px;
  }

  &-left {
    border-right: 1px solid #E5E5E5;
    padding: 20px 26px 21px;
    box-sizing: border-box;
    width: 40%;

    @include respond-to(1100px) {
      width: 50%;
    }

    @include respond-to(800px) {
      width: 100%;
    }

    @include respond-to(700px) {
      padding: 12px;
    }

    &-image {
      width: 100%;
    }

  }

  &-right {
    border-left: 1px solid #E5E5E5;
    width: 60%;
    box-sizing: border-box;
    padding: 20px 26px 21px;

    @include respond-to(1100px) {
      width: 50%;
    }

    @include respond-to(800px) {
      width: 100%;
    }

    @include respond-to(700px) {
      padding: 12px;
      border-left-width: 0;
    }

  }

  &-title {
    font-family: 'Open Sans Semibold', sans-serif;
    color: #2D384E;
    font-size: 24px;
    margin: 0;
    padding: 0;

    @include respond-to(700px) {
      font-size: 16px;
      line-height: 1.1;
    }

  }

  &-elements {
    margin-top: 30px;

    @include respond-to(700px) {
      margin-top: 20px;
    }

  }

  &-element {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    color: #2D384E;

    &-label {
      width: 140px;

      @include respond-to(700px) {
        width: auto;
      }

    }

    &-value {
      font-family: 'Open Sans Semibold', sans-serif;
      width: calc(100% - 140px);

      @include respond-to(700px) {
        width: auto;
        margin-left: 10px;
      }

    }

    @include respond-to(700px) {
      font-size: 14px;
    }

  }

  &-description {
    margin-top: 20px;
    font-size: 16px;
    color: #2D384E;

    @include respond-to(700px) {
      font-size: 14px;
    }

  }

  &-upload-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2a8eff;
    box-sizing: border-box;
    border-radius: 30px;
    height: 37px;
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    width: 180px;
    cursor: pointer;
    margin-top: 20px;
    transition: 0.3s;
    background-color: #2a8eff;
    color: #FFFFFF;

    &:hover {
      opacity: .8;
    }

  }

}

.landing-bottom-area {
  width: 100%;
  height: 340px;
  background: url(../assets/landing/sign-up.jp2) no-repeat center center;
  background-size: cover;
  padding-top: 90px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;

  @include respond-to(700px) {
    height: auto;
    padding: 30px 20px;
  }

  &-title {
    display: block;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-family: "Open Sans Semibold", sans-serif;
    font-size: 26px;

    @include respond-to(700px) {
      font-size: 18px;
    }

  }

  &-sub-title {
    display: block;
    font-size: 26px;
    text-align: center;
    color: #fff;
    line-height: 26px;
    margin-top: 28px;
    text-transform: uppercase;
    font-family: "Open Sans Light", sans-serif;

    @include respond-to(700px) {
      font-size: 14px;
      line-height: 1.4;
      margin-top: 20px;
    }

  }

  &-button {
    color: #fff;
    width: 233px;
    padding: 0;
    line-height: 43px;
    height: 44px;
    text-decoration: none;
    display: block;
    text-align: center;
    text-transform: uppercase;
    margin: 40px auto 0;
    border-radius: 300px;
    border: 1px solid #fff;
    font-size: 14px;
    font-family: "Open Sans Semibold", sans-serif;
    background-color: transparent;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      background-color: $blue;
      border-color: $blue;
      color: #FFFFFF;
    }

    @include respond-to(700px) {
      width: auto;
      padding-left: 18px;
      padding-right: 18px;
      margin-top: 20px;
      font-size: 12px;
      height: 30px;
      line-height: 29px;
    }

  }

}

.create-page-block-element, .custom-text-field {

  &.error {

    & .custom-text-field-input, & .custom-select-top, & .custom-text-field-textarea {
      border-color: #CC0000;
    }


  }

}

.create-page-block-element-inner {
  position: relative;
}

.create-page-block-element-copy-button {
  position: absolute;
  right: 0;
  top: -5px;
  height: 24px;
  display: flex;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 12px;
  align-items: center;
  box-shadow: none;
  border-width: 1px;
  font-size: 12px;
  border-style: solid;
  border-radius: 4px;
  transition: all 0.2s ease-out;
  outline: none;
  text-decoration: none;
  background-color: #2a8eff;
  border-color: #2a8eff;
  color: #FFFFFF;
  cursor: pointer;
}

.warning-window {
  height: 470px;

  &-content {
    height: 350px;
    padding: 20px 30px;
    box-sizing: border-box;
  }

  &-text {
    font-size: 14px;
    line-height: 1.1;
    color: #000000;
    display: block;
    margin-top: 15px;

    & b {
      font-family: 'Open Sans Bold', sans-serif;
    }

    &:first-child {
      margin-top: 0;
    }

  }

}

.confirm-window {

  height: 260px;

  &-text {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    display: block;
    margin-top: 20px;

    & b {
      font-family: 'Open Sans Bold';
    }

    &:first-child {
      margin-top: 0;
    }

  }

  &-content {
    height: 140px;
    padding: 20px 30px;
    box-sizing: border-box;
  }

}

.filter-modal-window {

  width: 400px;
  height: 500px;

  &-content {
    padding-left: 20px;
    padding-right: 20px;
    height: 360px;
    overflow-y: auto;
    box-sizing: border-box;
  }

}

.custom-text-field-date-range {
  display: flex;
  flex-direction: row;

  & .custom-text-field-date {
    width: calc(100% - 10px);

    &:nth-child(2) {
      margin-left: 20px;
    }

  }

}

.ReactModal__Overlay--after-open {
  z-index: 10000;
}

.filter-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100vh;
  background-color: #FFFFFF;
  z-index: 200;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-right: 1px solid #E5E5E5;
  overflow-y: auto;

  &-content {
    margin-top: 50px;

    & .gallery-videos-left {
      margin-top: 0;
    }

    & .gallery-videos-left-inner {
      position: relative;
      border-radius: 0;
      width: 100%;
      top: 0;
    }

  }

  &-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 201;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #E5E5E5;
    width: 50px;
    height: 50px;
    background-color: #ffffff;

    &-icon {
      height: 30px;
      width: 30px;
    }

  }

}

.mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 200px;
  height: 100vh;
  background-color: #FFFFFF;
  z-index: 200;
  box-shadow: 0 0 3px rgba(0,0,0,.3);
  border-right: 1px solid #E5E5E5;


  &-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 201;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid #E5E5E5;
    width: 50px;
    height: 50px;
    background-color: #ffffff;

    &-icon {
      height: 30px;
      width: 30px;
    }

  }

  &-top {
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #E5E5E5;
  }

  &-links {
    list-style: none;
    margin: 30px 0 0;
    padding: 0 30px;
    box-sizing: border-box;

    & li {
      margin-top: 12px;

      & a {
        font-size: 15px;
        line-height: 18px;
        color: #2D384E;
        text-decoration: none;
      }

    }

  }

  &-buttons {
    margin-top: 24px;
    padding: 0 30px;
    box-sizing: border-box;
  }

  &-auth-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2a8eff;
    box-sizing: border-box;
    border-radius: 28px;
    height: 28px;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
    background-color: transparent;
    border: 1px solid #2a8eff;
    color: #2a8eff;
  }

  &-register-button {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #2a8eff;
    box-sizing: border-box;
    border-radius: 28px;
    height: 28px;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
    transition: 0.3s;
    background-color: #2a8eff;
    color: #FFFFFF;
  }

}

.video-upload-information {

  padding: 20px;
  box-sizing: border-box;
  border-top: 1px solid #E5E5E5;

  &-title {
    color: #2D384F;
    font-family: "Open Sans Bold", sans-serif;
    font-size: 16px;
    line-height: 19px;
  }

  & ul {

    li {
      margin-top: 20px;
      font-size: 14px;
      line-height: 17px;
      color: #2D384F;
    }

  }

}

.stringer-page-comment {
  margin-top: 20px;

  &-bottom {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
  }

  &-submit {
    margin-left: auto;
    border-radius: 4px;
    cursor: pointer;
  }

}

.video-upload-error {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-bottom: 20px;
  color: #CC0000;
  font-family: 'Open Sans Semibold', sans-serif;
}

.em-area {

  &-top {
    display: flex;
    flex-direction: row;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 30px 40px;
    box-sizing: border-box;
    margin-top: 20px;
    position: relative;
  }

  &-icons {
    position: absolute;
    top: 20px;
    right: 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: rgba(229, 229, 229, 1);
    font-size: 30px;
    margin-left: 15px;

    &:hover {
      color: rgba(42, 142, 254, 1);
    }

  }

  &-name {
    color: rgba(45, 56, 79, 1);
    font-size: 25px;
    font-weight: 700;
    line-height: 31px;
    display: block;
    margin-top: 10px;
  }

  &-position {
    display: block;
    margin-top: 14px;
    color: rgba(45, 56, 79, 1);
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }

  &-activity {
    margin-top: 3px;
    display: block;
    color: rgba(151, 151, 151, 1);
    font-size: 14px;
    line-height: 17px;
  }

  &-avatar {
    width: 136px;
    height: 136px;
    border-radius: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

    &-circle {
      width: 24px;
      height: 24px;
      border: 10px solid #FFFFFF;
      border-radius: 44px;
      background-color: rgba(193, 193, 193, 1);
      position: absolute;
      right: -12px;
      bottom: 0;
    }

  }

  &-email {
    display: block;
    margin-top: 10px;
    color: rgba(45, 56, 79, 1);
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    & a {
      text-decoration: none;
      font-weight: 400;
      color: rgba(45, 56, 79, 1);
    }

  }

  &-phone {
    display: block;
    margin-top: 10px;
    color: rgba(45, 56, 79, 1);
    font-size: 16px;
    line-height: 19px;
    font-weight: 600;

    & a {
      text-decoration: none;
      font-weight: 400;
      color: rgba(45, 56, 79, 1);
    }

  }

  &-right {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    height: 100%;
    width: calc(100% - 200px);

    &-col {
      width: 50%;
      box-sizing: border-box;
      height: 136px;
      padding-left: 40px;

      &:first-child {
        border-right: 1px solid rgba(229, 229, 229, 1);
      }

    }

  }

}

.video-page-block-top {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.video-page-partners-loader {
  position: relative;
  height: 200px;
}

.contact-page {
  padding-top: 80px;
  padding-bottom: 80px;
  background-image: url(../assets/landing/background-faq.png);
  background-repeat: no-repeat;
  margin-top: 2px;
  min-height: calc(100vh - 130px);
  box-sizing: border-box;

  @include respond-to(700px) {
    background: none;
  }

  &-title {
    font-size: 30px;
    font-family: 'Open Sans Bold', sans-serif;
    color: #000000;
    text-transform: uppercase;
    text-align: center;
    display: block;
    margin: 0;
    padding: 0;

    @include respond-to(700px) {
      font-size: 24px;
      line-height: 1.1;
    }

  }

  &-element {
    margin-top: 20px;
  }

  &-row {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }

  &-left {
    width: 50%;

    @include respond-to(900px) {
      width: 100%;
    }

  }

  &-right {
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @include respond-to(900px) {
      width: 100%;
    }

  }

  &-col {
    width: 50%;

    @include respond-to(900px) {
      width: 100%;
    }

  }

  &-content {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;
    box-sizing: border-box;

    @include respond-to(900px) {
      flex-direction: column;
      column-gap: 0;
      row-gap: 20px;
    }

  }

  &-container {
    max-width: 1280px;
    margin: auto;

    @include respond-to(1300px) {
      max-width: 1180px;
    }

    @include respond-to(1200px) {
      max-width: 1080px;
    }

    @include respond-to(1100px) {
      max-width: 980px;
    }

    @include respond-to(1000px) {
      max-width: 880px;
    }

    @include respond-to(900px) {
      max-width: 780px;
    }

    @include respond-to(800px) {
      max-width: 680px;
    }

    @include respond-to(700px) {
      width: 100%;
      padding-left: 12px;
      padding-right: 12px;
      box-sizing: border-box;
    }

  }

  &-image {
    width: 40%;

    @include respond-to(700px) {
      display: none;
    }

    & img {
      width: 345px;
      height: 383px;
    }

  }

  &-submit-button {
    height: 32px;
    display: flex;
    border: 0;
    box-shadow: none;
    background-color: $blue;
    border-radius: 24px;
    align-items: center;
    padding-left: 16px;
    padding-right: 10px;
    flex-direction: row;
    transition: all .2s ease-out;
    text-decoration: none;
    width: 100%;
    margin-top: 20px;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
  }

}

.contact-form {
  width: 100%;
  padding-top: 40px;
}

.gallery-download {
  margin-top: 20px;

  &-title {
    display: block;
    font-family: "Open Sans Semibold", sans-serif;
    color: #2D384E;
    font-size: 20px;
  }

  &-files {
    margin-top: 10px;
  }

  &-file {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }

    & i {
      color: $blue;
      cursor: pointer;
      margin-left: 5px;
      font-size: 20px;
    }

  }

}

